import axios from 'axios';
import bookInterceptors from './interceptors/book';
import _ from 'lodash';

const axInstance = axios.create({
  timeout: 30000
});

bookInterceptors.request.forEach((interceptor) => {
  const toUseInterceptor = interceptor();
  if (_.isArray(toUseInterceptor)) {
    axInstance.interceptors.request.use(...toUseInterceptor);
  } else {
    axInstance.interceptors.request.use(toUseInterceptor);
  }
});

bookInterceptors.response.forEach((interceptor) => {
  const toUseInterceptor = interceptor();
  if (_.isArray(toUseInterceptor)) {
    axInstance.interceptors.response.use(...toUseInterceptor);
  } else {
    axInstance.interceptors.response.use(toUseInterceptor);
  }
});

export const post = (url, data, config) => {
  return axInstance.post(url, data, {
    ...config
  });
};

export const get = (url, config) => {
  return axInstance.get(url, config);
};
