import { TRIP_TYPE, TRIP_TYPE_ENUM, CABIN_TYPE_ENUM } from '@/constants/flight';
import { useAuthLimit } from '@/composables/useAuthLimit';
import { getDefaultPassengers, getPassengersValue } from '@/utils/flight';
export function useFlightSearchBase() {
  const {
    t
  } = useI18n();
  const {
    limitedCabinList,
    cabinDefaultValue
  } = useAuthLimit();
  const tripTypes = computed(() => {
    return TRIP_TYPE().map(item => {
      return {
        ...item,
        label: t(`tripType.${item.value}`)
      };
    });
  });
  const cabinTypes = computed(() => {
    return limitedCabinList.value;
  });
  const tripTypeSeleted = ref(TRIP_TYPE_ENUM.ONEWAY);
  const passengers = ref(getDefaultPassengers());
  const cabinSelected = ref(cabinDefaultValue.value);
  function initSearhBaseParams(val) {
    const {
      tripType,
      adult,
      child,
      cabin
    } = val;
    tripTypeSeleted.value = tripType || TRIP_TYPE_ENUM.ONEWAY;
    passengers.value = {
      adult: adult || 1,
      child: child || 0
    };
    cabinSelected.value = cabin || cabinDefaultValue.value;
  }
  function getQueryParams() {
    const params = {
      tripType: tripTypeSeleted.value,
      ...getPassengersValue(passengers)
    };
    if (CABIN_TYPE_ENUM.ALL !== cabinSelected.value) {
      params['cabin'] = cabinSelected.value;
    }
    return params;
  }
  return {
    tripTypes,
    cabinTypes,
    tripTypeSeleted,
    passengers,
    cabinSelected,
    initSearhBaseParams,
    getQueryParams
  };
}