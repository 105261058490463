import _ from 'lodash';
import Decimal from 'decimal.js';

/**
 *
 * @param {*} value 要处理的值
 * @returns Number 返回数字
 */
export const toNumber = (value) => _.toNumber(value);
/**
 * 两数和
 * @param {Number} n1
 * @param {Number} n2
 * @returns Number
 */
export const add = (n1, n2) => {
  return Decimal.add(n1, n2).toNumber();
};

/**
 * 两数差
 * @param {Number} n1
 * @param {Number} n2
 * @returns Number
 */
export const subtract = (n1, n2) => {
  return Decimal.sub(n1, n2).toNumber();
};

/**
 * 两数相乘
 * @param {Number} n1
 * @param {Number} n2
 * @returns Number
 */
export const multiply = (n1, n2) => {
  return Decimal.mul(n1, n2).toNumber();
};

/**
 * 两数相除
 * @param {Number} n1
 * @param {Number} n2
 * @returns Number
 */
export const divide = (n1, n2) => {
  return Decimal.div(n1, n2).toNumber();
};

/**
 * 得到多个数之和
 * @param {Number[]} nums
 * @returns Number
 */
export const sum = (nums) => {
  return nums.reduce((total, n) => {
    return add(total, n);
  }, 0);
};

/**
 * 求平均值
 * @param {*} arr 数组
 * @param {*} iteratee 调用每个元素的迭代函数或属性名
 * @param {*} precision 保留精度 see https://www.lodashjs.com/docs/lodash.floor
 * @returns
 */
export const mean = (arr, iteratee, precision = 0) => {
  const total = arr.reduce((total, cur) => {
    let n = 0;
    if (iteratee) {
      n = typeof iteratee === 'function' ? iteratee(cur) : cur[iteratee];
    }
    return add(total, n);
  }, 0);

  return _.floor(divide(total, arr.length || 1), precision);
};

/**
 * 求数组中最大值
 * @param {*} arr 数组
 * @param {*} iteratee 调用每个元素的迭代函数或属性名
 * @returns
 */
export const max = (arr, iteratee = (o) => o) => {
  return _.maxBy(arr, iteratee);
};

/**
 * 求数组中小值
 * @param {*} arr
 * @param {*} iteratee 调用每个元素的迭代函数或属性名
 * @returns
 */
export const min = (arr, iteratee = (o) => o) => {
  return _.minBy(arr, iteratee);
};

/**
 * 保留小数点后rm位
 * @param {float} num 原数字
 * @param {*} rm 保留位数
 * @returns {String}
 */
export const toFixed = (num, rm) => {
  return new Decimal(num).toFixed(rm);
};

/**
 * 如果此数的值为负值，则返回true，否则返回false。
 * @param {*} num
 * @returns
 */
export const isNegative = (num) => {
  return new Decimal(num).isNegative();
};

/**
 * 返回一个新的Decimal，其值为该Decimal值的绝对值，即大小。
 * @param {*} num
 * @returns
 */
export const absoluteValue = (num) => {
  return new Decimal(num).absoluteValue();
};
