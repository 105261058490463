/**
 * 判断当前环境是否为小程序
 * @returns Boolean
 */
export function isMiniProgram() {
  const ua = window.navigator.userAgent.toLowerCase();

  return ua.includes('miniProgram');
}

/**
 * 判断当前环境是否为微信网页
 * @returns Boolean
 */
export function isWxWeb() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') != -1;
}

/**
 * 判断是否为移动端
 * @returns Boolean
 */
export const isMobile = (function () {
  return typeof window.orientation !== 'undefined';
})();
