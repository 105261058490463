import axios from 'axios';
import gtInterceptors from './interceptors/gtBook';
import _ from 'lodash';

const gtAxInstance = axios.create({
  timeout: 500000
});

gtInterceptors.request.forEach((interceptor) => {
  const toUseInterceptor = interceptor();
  if (_.isArray(toUseInterceptor)) {
    gtAxInstance.interceptors.request.use(...toUseInterceptor);
  } else {
    gtAxInstance.interceptors.request.use(toUseInterceptor);
  }
});

gtInterceptors.response.forEach((interceptor) => {
  const toUseInterceptor = interceptor();
  if (_.isArray(toUseInterceptor)) {
    gtAxInstance.interceptors.response.use(...toUseInterceptor);
  } else {
    gtAxInstance.interceptors.response.use(toUseInterceptor);
  }
});

export const get = (url, config) => {
  return gtAxInstance.get(url, config);
};

export const post = (url, data, config) => {
  return gtAxInstance.post(url, data, config);
};
