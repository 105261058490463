import LayoutOutward from '@/layout/LayoutOutward.vue';

export const exchangeRouters = {
  path: '/exchange',
  component: LayoutOutward,
  children: [
    {
      path: '',
      name: 'exchangeInit',
      component: () =>
        import('../views/exchange/exchangeInit/exchangeInitView.vue')
    },
    {
      path: 'search',
      name: 'exchangeSearch',
      component: () =>
        import('../views/exchange/exchangeSearch/ExchangeSearchView.vue')
    },
    {
      path: 'order',
      name: 'exchangeOrder',
      component: () =>
        import('../views/exchange/exchangeOrder/ExchangeOrderView.vue')
    },
    {
      path: 'success',
      name: 'exchangeSuccess',
      component: () =>
        import('../views/exchange/exchangeResult/ExchangeSuccessView.vue')
    }
  ]
};
