export default {
  "common": {
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "flight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flights"])},
    "NoMatchedAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no matching airports"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reserve"])},
    "cabin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabin"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "noFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No flight information available"])},
    "noFilteredFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is currently no suitable flight information available"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tax"])},
    "layover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["layover in"])},
    "flightTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flight time"])},
    "layoverTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["layover time"])},
    "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrival"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
    "perPassenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per person"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
    "fareBasis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare Basis"])},
    "bookingClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Class"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné"])}
  },
  "dateTime": {
    "duration_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
    "duration_hour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h"])},
    "duration_minute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m"])},
    "duration_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "s"])}
  },
  "flightSearch": {
    "departingFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure"])},
    "goingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
    "departReturnDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure-Return Date"])},
    "departDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure date"])},
    "cabinClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabin Class"])},
    "addAnotherFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another flight"])},
    "moreAirlineFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more flights from this airline"])},
    "morePriceFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View more flights at this price"])},
    "stopText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["nonstop"]), _normalize(["1 stop"]), _normalize([_interpolate(_named("count")), " stops"])])},
    "travelerNum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No passengers"]), _normalize(["1 passenger"]), _normalize([_interpolate(_named("count")), " passengers"])])},
    "searchEmptyTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the search information is filled in completely"])}
  },
  "flightRule": {
    "refundPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restocking fee"])},
    "changePenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change fee"])},
    "endorsementPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarantee deduction fee"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de remboursement"])},
    "luggageAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bagages autorisés jusqu'à ", _interpolate(_named("count"))])},
    "luggageDetail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Section ", _interpolate(_named("no")), " itinéraire"])},
    "luggageNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les bagages ne sont pas autorisés"])},
    "luggageRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franchise bagages"])}
  },
  "tripType": {
    "oneway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-way"])},
    "roundtrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round-trip"])},
    "multicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-city"])}
  },
  "passenger": {
    "adult": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult"])},
      "num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 adult"]), _normalize([_interpolate(_named("count")), " adults"])])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(18 years old and above)"])}
    },
    "child": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child"])},
      "num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 child"]), _normalize([_interpolate(_named("count")), " childs"])])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(2-11 years old)"])}
    }
  },
  "cabin": {
    "A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
    "P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Economy"])},
    "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])}
  },
  "cabinVal": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Economy"])}
  ],
  "tips": {
    "passengerLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can only book flights for a maximum of ", _interpolate(_named("num")), " passengers."])},
    "seatLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " tickets left"])},
    "seatLeftEnough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adequate seating"])}
  },
  "filter": {
    "group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group by"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airline company"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])}
      ]
    },
    "stop": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stops"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nonstop"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 stop"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 or more stops"])}
      ]
    },
    "airline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airline company"])},
      "options": [
        
      ]
    },
    "time": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depature Time"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival Time"])}
      ],
      "tips": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time unlimited"])}
      ]
    }
  },
  "sort": {
    "cheapest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheapest"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("num"))])}
    },
    "earliest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earliest"])}
    },
    "fastest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastest"])}
    }
  },
  "home": {
    "bookTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book flights with confidence"])},
    "findYourFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your flight"])}
  },
  "book": {
    "flightDetailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight details"])},
    "summaryCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Summary"])},
    "totalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "flightInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight information"])},
    "departureInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure information"])},
    "returnInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return information"])},
    "passengerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger"])},
    "paymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
    "payTypeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit or debit card "])},
    "cardHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder information"])},
    "Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
    "bookConfirmDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the change and cancellation rules for this purchase."])},
    "confirmTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select the checkbox above to agree to the fare rules and terms of use."])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "noDataToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a itinerary for booking"])},
    "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seatmap"])},
    "seatsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a seat"])},
    "fareSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare Selection"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "seatmapError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching data error!"])}
  },
  "passengerForm": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "firstNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid first name"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle name"])},
    "middleNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid middle name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "lastNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid last name"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "birthdayPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a valid birthday"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "Femal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Women"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men"])},
    "cardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID type"])},
    "cardNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID number"])},
    "cardNumPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid ID number"])},
    "cardExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID expired"])},
    "cardExpiredPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid ID expired"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "cardIssuePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issuing country"])}
  },
  "cardType": {
    "P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])}
  },
  "payment": {
    "creditCardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card type"])},
    "creditCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
    "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
    "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "cardVerificationNumberPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the verification code"])},
    "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of issuing bank"])},
    "bankTollFreeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank tollfree number"])},
    "relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship with cardholders"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder's phone number"])},
    "relationships": {
      "Spouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["spouse"])},
      "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["children"])},
      "Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent"])},
      "Relative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relatives"])},
      "Friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friend"])},
      "Self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["self"])},
      "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])}
    },
    "paymentTypeCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Payment"])}
  },
  "contact": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "addressPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid address"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
    "postcodePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid post code"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "mobilePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid phone number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email"])}
  },
  "loadingText": {
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单创建中... "])},
    "orderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预定成功!"])},
    "paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单支付中..."])},
    "issueTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票中..."])},
    "issueSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票成功"])}
  },
  "order": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单信息"])},
    "summaryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单概要"])}
  },
  "responseMsg": {
    "1050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您所选价格已无位，请重新搜索"])},
    "1055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程信息错误，请重新搜索"])},
    "1056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客信息错误"])},
    "1058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人信息错误"])},
    "1059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选行程价格有变化，请重新搜索"])},
    "1060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单失败，请稍后重试"])},
    "1061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单失败，请稍后重试"])},
    "1062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证件有效期超出限制范围，请重新填写"])},
    "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据过期，请重新搜索"])},
    "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程信息错误"])},
    "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["舱位或价格变化，请重新搜索"])},
    "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小舱位变化，请重新搜索"])},
    "1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大舱等变化，请重新搜索"])},
    "1209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统错误，请稍后重试"])},
    "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数校验失败，请重新填写"])},
    "1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数校验失败，请稍后重试"])},
    "1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数校验失败，请稍后重试"])},
    "1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单不存在，请重新搜索"])},
    "1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单状态异常，请重新搜索"])},
    "1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超出支付时限，请重新搜索"])},
    "1407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付校验失败，请重新搜索"])},
    "1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数校验失败，请稍后重试"])},
    "1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单不存在，请重新搜索"])},
    "1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无权操作该订单"])},
    "1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单不为等待支持状态，请重新搜索"])},
    "1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不支持的支付方式"])},
    "1506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超出支付时限，请重新搜索"])},
    "1507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付验证失败，请重新搜索"])},
    "1510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户余额不足"])},
    "1511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失败，请重新搜索"])},
    "1512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付处理中"])},
    "1513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单已支付"])},
    "ticketPriceChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选行程价格有变化，请重新搜索"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失败，请稍后重试"])},
    "priceChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选行程价格有变化，请重新搜索"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登 录"])},
    "logoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注销"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账号"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "loginInfoError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户名或密码错误"])},
    "loginFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失败，请稍后重试"])},
    "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
    "accountRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写账户名"])},
    "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写密码"])}
  },
  "validate": {
    "emptyTip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter ", _interpolate(_named("key"))])},
    "passenger": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the passenger information is filled in completely"])}
    },
    "contact": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the contact information is filled in completely"])}
    },
    "payment": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the payment information is filled in completely"])}
    }
  }
}