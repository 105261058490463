import { ENABLE_PASSENGER_TYPE, PASSENGER_TYPE, PASSENGER_ENUM,
// BRAND_ATTRS,
FARE_TYPES, FARE_PRIVATE_TYPES, GDS_TITLE } from '@/constants/flight';

/**
 *
 * @param {string} code 航空公司code
 * @returns 航空公司logo
 */
export const loadRemoteAirlineLogo = code => {
  return !code ? '' : `https://goprivate.wspan.com/sharedservices/images/airlineimages/logoAir${code}.gif`;
};

/**
 * 获取默认乘客数据
 * @returns
 */
export const getDefaultPassengers = () => {
  const passengers = ENABLE_PASSENGER_TYPE.map(k => {
    return {
      [k]: k === PASSENGER_TYPE.ADULT ? 1 : 0
    };
  }).reduce((obj, cur) => {
    Object.assign(obj, cur);
    return obj;
  }, {});
  return passengers;
};

/**
 *
 * @param {Ref} passengersRef
 */
export const getPassengersValue = passengersRef => {
  const pr = toRaw(toValue(passengersRef));
  return Object.keys(pr).filter(k => {
    return pr[k] > 0;
  }).map(k => {
    return {
      [PASSENGER_ENUM[k]['texts']]: pr[k]
    };
  }).reduce((obj, cur) => {
    Object.assign(obj, cur);
    return obj;
  }, {});
};
export function getBrandAttrIcon(attr) {
  const brands = {
    carryOnBag: {
      keys: ['carryon', 'carry-on'],
      icon: 'i-fluent-briefcase-32-regular'
    },
    checkedBag: {
      keys: ['checked', 'checked bag', 'checkedbag'],
      icon: 'i-mdi:bag-checked'
    },
    refundable: {
      keys: ['refundable', 'refund'],
      icon: 'i-mdi-credit-card-check-outline'
    },
    extraSpaciousSeat: {
      keys: ['extra-spacious seat'],
      icon: 'i-mdi-seat-recline-extra'
    },
    changeFees: {
      keys: ['change fees'],
      icon: 'i-mdi-calendar-refresh-outline'
    },
    seat: {
      keys: ['choose your seat', 'seatassignment'],
      icon: 'i-mdi-seat'
    },
    upgrade: {
      keys: ['upgrades'],
      icon: 'i-mdi-arrow-up-bold-hexagon-outline'
    },
    roomierSeat: {
      keys: ['roomier seat'],
      icon: 'i-mdi-seat-recline-extra'
    },
    changes: {
      keys: ['changes', 'rebooking'],
      icon: 'i-mdi-calendar-refresh-outline'
    },
    meals: {
      keys: ['meals'],
      icon: 'i-fluent-food-24-filled'
    },
    wiFi: {
      keys: ['wifi'],
      icon: 'i-mdi-wifi'
    },
    // mileageAccrual: {
    //   keys: ['mileage accrual'],
    //   icon: 'i-mdi-checkbox-multiple-blank-outline'
    // },
    entertainment: {
      keys: ['entertainment'],
      icon: 'i-fluent-headphones-sound-wave-32-filled'
    }
  };
  const brandQueryList = Object.values(brands);
  const returnVal = {
    words: attr,
    icon: 'i-mdi-checkbox-multiple-blank-outline'
  };
  const attrLower = attr.toLocaleLowerCase();
  brandQueryList.forEach(item => {
    let icon = '';
    item.keys.forEach(k => {
      if (attrLower.indexOf(k) > -1) {
        icon = item.icon;
        return;
      }
    });
    if (icon) {
      returnVal.icon = icon;
      return;
    }
  });
  return returnVal;

  // switch (attr) {
  //   case BRAND_ATTRS.ONE_CARRYON_BAG_INCLUDED:
  //     return {
  //       words: attr,
  //       icon: 'i-fluent-briefcase-32-regular'
  //     };
  //   case BRAND_ATTRS.REFUNDABLE:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-credit-card-check-outline'
  //     };
  //   case BRAND_ATTRS.EXTRA_SPACIOUS_SEAT:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-seat-recline-extra'
  //     };

  //   case BRAND_ATTRS.NO_CHANGE_FEES:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-calendar-refresh-outline'
  //     };
  //   case BRAND_ATTRS.CHOOSE_YOUR_SEAT:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-seat'
  //     };
  //   case BRAND_ATTRS.UPGRADES_AVAILABLE:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-arrow-up-bold-hexagon-outline'
  //     };
  //   case BRAND_ATTRS.NON_REFUNDABLE:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-credit-card-off-outline'
  //     };
  //   case BRAND_ATTRS.ROOMIER_SEAT:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-seat-recline-extra'
  //     };
  //   case BRAND_ATTRS.CARRYON_BAG_INCLUDED:
  //     return {
  //       words: attr,
  //       icon: 'i-fluent-briefcase-32-regular'
  //     };
  //   case BRAND_ATTRS.CHECKED_BAG_INCLUDED:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi:bag-checked'
  //     };
  //   case BRAND_ATTRS.CHOOSE_SEAT_FOR_A_FEE:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-seat'
  //     };
  //   case BRAND_ATTRS.CHANGES_NOT_ALLOWED:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi:calendar-lock'
  //     };
  //   case BRAND_ATTRS.CHANGE_ALLOWED_FOR_FEE:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-calendar-refresh-outline'
  //     };
  //   case BRAND_ATTRS.CHECKED_BAG_NOT_INCLUDED:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi:bag-checked'
  //     };
  //   default:
  //     return {
  //       words: attr,
  //       icon: 'i-mdi-checkbox-multiple-blank-outline'
  //     };
  // }
}
export const getGdsText = gds => {
  return GDS_TITLE[gds] ? GDS_TITLE[gds] : gds;
};
export const getFareTypeText = (publishFare, privateFareType) => {
  if (publishFare) return FARE_TYPES.PUBLISHED;
  if (privateFareType === FARE_PRIVATE_TYPES.COMM) return FARE_TYPES.COMMISSION_PUBLISHED;else if (privateFareType === FARE_PRIVATE_TYPES.SR || privateFareType === FARE_PRIVATE_TYPES.BULK) return FARE_TYPES.SR_OR_BULK;
  return privateFareType;
};