import "core-js/modules/es.array.push.js";
import { getOrdinal } from '@/utils/util';
const markerConfig = {
  type: 'line',
  color: '#3060B0'
};
export function useFlightMulti(trips = [{
  departure: '',
  arrival: '',
  departureDate: '',
  minDate: new Date()
}]) {
  const multiTrips = ref(trips);
  const defaultMarkerDates = [];
  if (trips.length > 1) {
    for (let i = 0; i < trips.length; i++) {
      defaultMarkerDates.push({
        ...markerConfig,
        date: trips[i].departureDate,
        text: `The ${i + 1}${getOrdinal(i)} route`
      });
    }
  }
  const markerDates = ref(defaultMarkerDates);
  function _cityDataTemplate({
    departure = '',
    minDate = null
  } = {}) {
    return {
      departure: departure,
      arrival: '',
      departureDate: '',
      minDate
    };
  }
  function addNewTrip() {
    const preIndex = multiTrips.value.length - 1;
    const newTrip = _cityDataTemplate({
      departure: multiTrips.value[preIndex].arrival || '',
      minDate: multiTrips.value[preIndex].departureDate || ''
    });
    const date = multiTrips.value[preIndex].departureDate;
    markerDates.value.push({
      ...markerConfig,
      date,
      text: `The ${preIndex + 1}${getOrdinal(preIndex + 1)} route`
    });
    multiTrips.value.push(newTrip);
  }
  function removeTrip(index) {
    multiTrips.value.splice(index, 1);
    markerDates.value.splice(index - 1, 1);
  }
  function getSearchData() {
    const dateStr = multiTrips.value.map(t => {
      return `${t.departure},${t.arrival},${t.departureDate}`;
    }).join('|');
    const data = {
      trips: dateStr
    };
    return data;
  }
  function validate() {
    let isPass = true;
    try {
      multiTrips.value.forEach(trip => {
        if (!trip.departure) {
          throw new Error('departure is empty');
        }
        if (!trip.arrival) {
          throw new Error('arrival is empty');
        }
        if (!trip.departureDate) {
          throw new Error('departureDate is empty');
        }
      });
    } catch (err) {
      isPass = false;
    }
    return isPass;
  }
  return {
    multiTrips,
    multiMarkerDates: markerDates,
    addNewTrip,
    removeTrip,
    getSearchData,
    validateSearch: validate
  };
}