import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
const breakpoints = useBreakpoints(breakpointsTailwind);

export const isMobile = () => {
  return breakpoints.smaller('md');
};

/**
 * 业务常量的使用方式扩展
 * @param {Array} source
    [
      { value: 1,label: '空间站',name: 'SPACE' },
      { value: 2, label: '实践任务', name: 'PRACTICE' },
      { value: 3, label: '其他系统', name: 'OTHERS' }
    ]
  @returns {Function} fn
    fn() ===> source
    fn(value) ===> label
    fn(name) ===> value
 */
export const createEnum = (source) => {
  const enumMap = new Map();
  source.map((item) => {
    enumMap.set(item.name, item.value);
    enumMap.set(item.value, item.label);
  });

  return function (key) {
    if (key !== undefined) {
      return enumMap.get(key) || '';
    } else {
      return source;
    }
  };
};

/**
 * 生成随机len位数的 英文大小写 数字 字符串
 * @param {*} len 要生成的字符位数
 * @returns
 */
export const generateRandomString = (len = 10) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < len; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const randomCharacter = characters.charAt(randomIndex);
    result += randomCharacter;
  }

  return result;
};

/**
 * 生成随机len位数的 英文大写 数字 字符串
 * @param {*} len 要生成的字符位数
 * @returns
 */
export const generateRandomStringUC = (len = 10) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';

  for (let i = 0; i < len; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const randomCharacter = characters.charAt(randomIndex);
    result += randomCharacter;
  }

  return result;
};

/**
 *
 * @param {Number} n 数位
 * @returns {String} - 数字英文顺序字符串，2 -> nd, 14 -> th
 */
export const getOrdinal = (n) => {
  let ord = 'th';

  if (n % 10 == 1 && n % 100 != 11) {
    ord = 'st';
  } else if (n % 10 == 2 && n % 100 != 12) {
    ord = 'nd';
  } else if (n % 10 == 3 && n % 100 != 13) {
    ord = 'rd';
  }

  return ord;
};

export const toInt = (str, d = 0) => {
  const r = parseInt(str);

  return isNaN(r) ? d : r;
};

export function getPropByPath(obj, path, strict) {
  let tempObj = obj;
  path = path.replace(/\[(\w+)\]/g, '.$1');
  path = path.replace(/^\./, '');

  let keyArr = path.split('.');
  let i = 0;
  for (let len = keyArr.length; i < len - 1; ++i) {
    if (!tempObj && !strict) break;
    let key = keyArr[i];
    if (key in tempObj) {
      tempObj = tempObj[key];
    } else {
      if (strict) {
        throw new Error('please transfer a valid prop path to form item!');
      }
      break;
    }
  }
  return {
    o: tempObj,
    k: keyArr[i],
    v: tempObj ? tempObj[keyArr[i]] : null
  };
}

/**
 * 将文本中 \r\n,\n替换为指定字符串
 * @param {*} text
 * @param {*} str
 * @returns
 */
export const transformBR = (text, str) => {
  // return text.replace(/(?:\r\n?|\n){1,}/g, str);
  return text.replace(/(?:\r\n?|\n|\/n){1,}/g, str);
};
