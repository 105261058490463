import '@unocss/reset/tailwind.css';
import 'uno.css';

// move global css into glogal chunk
import 'bootstrap/scss/bootstrap.scss';
import './assets/sass/all.scss';

// import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import bootComponent from './components/boot';
import pinia from './store';
// import miniprogram from './plugins/miniprogram';
// import intercom from './plugins/intercom';

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';

// Fort Awesome
// import '@fortawesome/fontawesome-free/css/all.min.css'
// import '@fortawesome/fontawesome-free/js/all.min.js'
import './router/router.config.js';

import setup from './setup';

const app = createApp(App);

// app.use(intercom);
app.use(bootComponent);
app.use(VueAxios, axios);
// app.use(vuetify)
app.use(router);
app.use(pinia);
app.use(setup);
app.mount('#app');
