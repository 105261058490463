export default {
  "common": {
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登 录"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注 册"])},
    "flight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机票"])},
    "NoMatchedAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有匹配的机场"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预定"])},
    "cabin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["舱位"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
    "noFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无航班信息"])},
    "noFilteredFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无合适航班信息"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中..."])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["税费"])},
    "layover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停留"])},
    "flightTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飞行时间"])},
    "layoverTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停留时间"])},
    "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到达"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已选择"])},
    "perPassenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每人"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无数据"])},
    "fareBasis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票价基础"])},
    "bookingClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["舱位"])},
    "requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求中..."])},
    "systemBusy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统繁忙，请稍后再试"])},
    "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索中..."])},
    "aircraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机型"])},
    "priceBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础票价"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])}
  },
  "dateTime": {
    "duration_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "天"])},
    "duration_hour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "小时"])},
    "duration_minute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "分钟"])},
    "duration_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "秒"])}
  },
  "flightSearch": {
    "departingFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出发地"])},
    "goingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目的地"])},
    "departReturnDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出发日期 - 返回日期"])},
    "departDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出发日期"])},
    "cabinClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客舱等级"])},
    "addAnotherFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加另一个航班"])},
    "moreAirlineFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看此航空公司的更多航班"])},
    "morePriceFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看此价格的更多航班"])},
    "stopText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["直达"]), _normalize(["中转1次"]), _normalize(["中转", _interpolate(_named("count")), "次"])])},
    "travelerNum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["无旅客"]), _normalize(["1名旅客"]), _normalize([_interpolate(_named("count")), "名旅客"])])},
    "searchEmptyTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认搜索信息填写完整"])}
  },
  "flightRule": {
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退改费"])},
    "refundPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退订费"])},
    "changePenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改签费"])},
    "endorsementPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担保扣费"])},
    "luggageRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行李额"])},
    "luggageDetail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["第", _interpolate(_named("no")), "段行程"])},
    "baggagesNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允许托运行李"])},
    "baggagesAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许托运行李"])},
    "baggagesAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许托运最多不超过 ", _interpolate(_named("count")), " 的行李"])},
    "baggagesAllowPiecesText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许托运最多不超过 ", _interpolate(_named("count")), " 件的行李"])},
    "carryonNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允许携带随身行李"])},
    "carryonAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许携带随身行李"])},
    "carryonAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最多不超过 ", _interpolate(_named("count")), " 的随身行李"])},
    "carryonAllowPiecesText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最多不超过 ", _interpolate(_named("count")), "件 的随身行李"])},
    "regulationsAccording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按航司规定"])},
    "baggagesAllowDimensionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许托运最大不超过 ", _interpolate(_named("count")), " 的行李"])},
    "carryonAllowDimensionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最大不超过 ", _interpolate(_named("count")), " 的随身行李"])},
    "baggages": {
      "allowPiecePerWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许托运最多不超过 ", _interpolate(_named("piece")), " 件,", _interpolate(_named("weight")), " 的行李"])},
      "allowPiece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许托运最多不超过 ", _interpolate(_named("count")), " 件的行李"])},
      "allowWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许托运最多不超过 ", _interpolate(_named("weight")), " 的行李"])},
      "allowCommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许托运最多不超过 ", _interpolate(_named("text")), " 的行李"])},
      "notAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允许托运行李"])}
    },
    "carryon": {
      "allowPiecePerWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最多不超过 ", _interpolate(_named("piece")), " 件,", _interpolate(_named("weight")), " 的随身行李"])},
      "allowPiece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最多不超过 ", _interpolate(_named("count")), " 件的随身行李"])},
      "allowWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最多不超过 ", _interpolate(_named("weight")), " 的随身行李"])},
      "allowCommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最多不超过 ", _interpolate(_named("text")), " 的随身行李"])},
      "notAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允许携带随身行李"])}
    },
    "luggageAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最多不超过", _interpolate(_named("count")), "的行李"])},
    "luggageAllowPiecesText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最多不超过", _interpolate(_named("count")), "件的行李"])},
    "luggageNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允許携带行李"])},
    "unitPiece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件"])},
    "baggagesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["托运行李额"])},
    "carryonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["随身行李额"])}
  },
  "tripType": {
    "oneway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单程"])},
    "roundtrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往返"])},
    "multicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多程"])}
  },
  "passenger": {
    "adult": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成人"])},
      "num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1名成年人"]), _normalize([_interpolate(_named("count")), "名成年人"])])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(12岁及以上)"])}
    },
    "child": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儿童"])},
      "num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1名儿童"]), _normalize([_interpolate(_named("count")), "名儿童"])])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(2-11岁)"])}
    }
  },
  "cabin": {
    "A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经济舱"])},
    "P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高端经济舱"])},
    "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商务舱"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["头等舱"])}
  },
  "cabinVal": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经济舱"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商务舱"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["头等舱"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高端经济舱"])}
  ],
  "tips": {
    "passengerLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您最多只能为", _interpolate(_named("num")), "名旅客预订航班。"])},
    "seatLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["还剩 ", _interpolate(_named("text")), " 张票"])},
    "seatLeftOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还剩 1 张票"])},
    "seatLeftEnough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["座位充足"])}
  },
  "filter": {
    "group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分组"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航空公司"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])}
      ]
    },
    "stop": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经停"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直达"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经停1次"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经停超过2次"])}
      ]
    },
    "airline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航空公司"])},
      "options": [
        
      ]
    },
    "time": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出发时间"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到达时间"])}
      ],
      "tips": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间不限"])}
      ]
    },
    "time2": {
      "earlyMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["凌晨"])},
      "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["早晨"])},
      "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下午"])},
      "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晚上"])},
      "departureTimeInCity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("city")), "出发时间"])},
      "arrivalTimeInCity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("city")), "到达时间"])}
    },
    "depPort": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出发机场"])},
      "options": [
        
      ]
    },
    "arrPort": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到达机场"])},
      "options": [
        
      ]
    },
    "channel": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])},
      "options": [
        
      ]
    },
    "fare": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票价类型"])},
      "options": [
        
      ]
    }
  },
  "sort": {
    "cheapest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最便宜"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("num"))])}
    },
    "earliest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最早"])}
    },
    "fastest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最快"])}
    }
  },
  "home": {
    "bookTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["满怀信心地预订航班"])},
    "findYourFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找您的航班"])}
  },
  "book": {
    "flightDetailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航班详情"])},
    "summaryCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单摘要"])},
    "totalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "flightInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航班信息"])},
    "departureInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出发信息"])},
    "returnInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回信息"])},
    "passengerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客"])},
    "paymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
    "payTypeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡或借记卡"])},
    "cardHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持卡人信息"])},
    "Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用"])},
    "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系信息"])},
    "bookConfirmDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意此次购买的更改和取消规则。"])},
    "confirmTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须勾选上面的复选框，同意票价规则和使用条款。"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["购买"])},
    "noDataToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择行程进行预定"])},
    "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选座"])},
    "seatsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择座位"])},
    "fareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票价"])},
    "fareSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择票价"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加服务"])},
    "seatmapError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["座位数据获取失败!"])},
    "paynowOrReserveTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即或稍后支付"])},
    "prOptionPayNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即支付"])},
    "prOptionReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稍后支付"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预定"])},
    "prepareBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预定准备中..."])},
    "offerExpiredTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优惠时间已过，请返回重新搜索!"])},
    "flightTripIndexTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["第", _interpolate(_named("index")), "段行程"])},
    "seatTotalPriceTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "个座位总价"])},
    "commissionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佣金"])}
  },
  "passengerForm": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
    "firstNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的名字"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中名"])},
    "middleNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的中间名"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
    "lastNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的姓氏"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
    "birthdayPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的生日"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性别"])},
    "Femal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
    "cardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证件类型"])},
    "cardNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["护照号码"])},
    "cardNumPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的护照号码"])},
    "cardExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["护照有效期"])},
    "cardExpiredPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的日期"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
    "cardIssuePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["签发国"])},
    "infoTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客姓名必须与政府签发的打算在旅行期间使用的身份证明文件相符。"])},
    "knowTravellerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飞行常客信息"])},
    "knowTravellerFrequentFlyerProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常客计划"])},
    "knowTravellerFrequentFlyerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计划编号"])},
    "knowTravellerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客编号"])}
  },
  "cardType": {
    "P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["护照"])}
  },
  "payment": {
    "creditCardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡类型"])},
    "creditCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡号"])},
    "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效日期"])},
    "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月份"])},
    "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年份"])},
    "cardVerificationNumberPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入验证码"])},
    "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发卡行名称"])},
    "bankTollFreeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行免费电话"])},
    "relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与持卡人关系"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持卡人电话号码"])},
    "relationships": {
      "Spouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配偶"])},
      "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子女"])},
      "Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父母"])},
      "Relative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亲属"])},
      "Friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["朋友"])},
      "Self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人"])},
      "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])}
    },
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家/地区"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["省"])},
    "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮编"])},
    "paymentTypeCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Payment"])}
  },
  "contact": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的地址"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮编"])},
    "postcodePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的邮编"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机号"])},
    "mobilePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的手机号"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入有效的邮箱"])},
    "copyTraveller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人员姓名与第一位客人同步"])},
    "nameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])}
  },
  "loadingText": {
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单创建中... "])},
    "orderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预定成功!"])},
    "paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单支付中..."])},
    "issueTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票中..."])},
    "issueSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票成功"])}
  },
  "order": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单信息"])},
    "summaryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单概要"])},
    "myOrderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
    "orderListFilterDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "orderSearchBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "status_title_paymenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的预订等待支付"])},
    "status_title_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的预订已完成支付"])},
    "status_title_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的预订正在处理中"])},
    "status_title_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的预订已处理完成"])},
    "status_title_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的预订已取消"])},
    "status_content_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your order and we are now processing it. We will send you your booking confirmation and your e-ticket soon."])},
    "status_content_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your booking is confirmed and issued. We wish you a wonderful flight."])},
    "status_content_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is to confirm that your reservation has been cancelled and no charge has been applied. In accordance with our Company Policies and standards, aimed at maintaining maximum Customer security, we can assure you that all your personal and card information has been deleted from our system.\nWe are committed to maintaining the highest possible Customer and Security Service standards and looking forward to serving you again."])},
    "status_content_paymenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已为您开通支付通道，请点击支付按钮进行支付，请您在30分钟内支付，超时将自动取消，支付一经确认无法撤回。"])},
    "ticket_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票中..."])},
    "order_new_not_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若长时间无法加载订单详情，请稍后至订单页中查看。"])},
    "order_cancel_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预订取消失败"])},
    "orderDetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单详情"])},
    "toOrderDetail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["前往 ", _interpolate(_list(0))])},
    "sendItinerary_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送失败"])},
    "sendItinerary_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送成功"])},
    "receivingEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件邮箱"])},
    "receivingEmailRequred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写收件邮箱"])},
    "orderPriceSummaryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单细则"])},
    "orderPriceTotalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
    "orderListActionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择您的操作"])},
    "orderListActionSendItinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送行程"])},
    "orderListActionChangeFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改行程"])},
    "orderListActionChangeSeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选座"])},
    "orderListActionViewTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看/管理行程"])},
    "orderPaySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付成功"])},
    "status_title_issued_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票失败"])}
  },
  "responseMsg": {
    "1050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您所选价格已无位，请重新搜索"])},
    "1055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程信息错误，请重新搜索"])},
    "1056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客信息错误"])},
    "1058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人信息错误"])},
    "1059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选行程价格有变化，请重新搜索"])},
    "1060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单失败，请稍后重试"])},
    "1061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单失败，请稍后重试"])},
    "1062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["证件有效期超出限制范围，请重新填写"])},
    "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据过期，请重新搜索"])},
    "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程信息错误"])},
    "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["舱位或价格变化，请重新搜索"])},
    "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小舱位变化，请重新搜索"])},
    "1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大舱等变化，请重新搜索"])},
    "1209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统错误，请稍后重试"])},
    "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数校验失败，请重新填写"])},
    "1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数校验失败，请稍后重试"])},
    "1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数校验失败，请稍后重试"])},
    "1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单不存在，请重新搜索"])},
    "1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单状态异常，请重新搜索"])},
    "1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超出支付时限，请重新搜索"])},
    "1407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付校验失败，请重新搜索"])},
    "1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数校验失败，请稍后重试"])},
    "1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单不存在，请重新搜索"])},
    "1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无权操作该订单"])},
    "1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单不为等待支持状态，请重新搜索"])},
    "1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不支持的支付方式"])},
    "1506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超出支付时限，请重新搜索"])},
    "1507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付验证失败，请重新搜索"])},
    "1510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户余额不足"])},
    "1511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失败，请重新搜索"])},
    "1512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付处理中"])},
    "1513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单已支付"])},
    "ticketPriceChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选行程价格有变化，请重新搜索"])},
    "invalidFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程无效，请重新搜索"])},
    "invalidCraditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡未经授权"])},
    "invalidCraditCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡号无效"])},
    "invalidCraditCardExpiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡到期日期无效"])},
    "noTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无机票"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失败，请稍后重试"])},
    "priceChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选行程价格有变化，请重新搜索"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登 录"])},
    "logoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注销"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账号"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "loginInfoError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户名或密码错误"])},
    "loginFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失败，请稍后重试"])},
    "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
    "accountRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写账户名"])},
    "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写密码"])},
    "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码"])},
    "verificationCodeRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写验证码"])},
    "verificationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码错误"])},
    "verificationCodeSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送验证码"])},
    "sendCodeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送验证码成功"])},
    "sendCodeFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送验证码失败"])},
    "b2bUnRegisterNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮箱未注册"])},
    "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效的邮箱"])}
  },
  "validate": {
    "emptyTip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["请填写", _interpolate(_named("key"))])},
    "passenger": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认乘客信息填写完整"])}
    },
    "contact": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认联系人信息填写完整"])}
    },
    "payment": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认支付信息填写完整"])}
    }
  },
  "seatmap": {
    "passengerText": {
      "ADT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成人"])},
      "CHD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儿童"])}
    },
    "seatmapSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存座位信息成功"])},
    "seatmapSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存座位信息失败"])},
    "seatmapTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["座位"])}
  },
  "service": {
    "serviceSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存服务信息成功"])},
    "serviceSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存服务信息失败"])},
    "serviceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加服务"])}
  },
  "bookPolicy": {
    "restrictionRulesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票价限制和规则"])},
    "privacyPolicyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
    "restrictionRulesBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["规则"])}
  },
  "exchange": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
    "changingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改中..."])},
    "tripOutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去程"])},
    "tripInTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返程"])},
    "tripIndexTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["第", _interpolate(_named("index")), "程"])},
    "exchangeFormInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认信息填写完整"])},
    "exchangeFareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票价"])},
    "exchangeFeeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航司费用"])},
    "exchangeRequestFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交失败"])},
    "exchangeSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的换票已处理完成"])},
    "exchangeSuccessTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your booking is confirmed and reissued. We wish you a wonderful flight."])},
    "exchangeDataInvalidTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["换票数据无效！"])},
    "exchangeSearchFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索失败"])}
  },
  "pricing": {
    "getUserFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录失败"])},
    "priceFormInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写文本"])},
    "analyzingFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求失败"])}
  },
  "tour": {
    "tourTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当地玩乐"])}
  },
  "tourList": {
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索結果"])},
    "linesNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " 条线路"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["筛选"])},
    "clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除所有筛选条件"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无数据"])},
    "loadedAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已全部加载"])},
    "loadMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中"])},
    "sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序"])},
    "filterKeys": {
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
      "destin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目的地"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年份"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅游日期"])},
      "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程主题"])},
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程天数"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["途经城市"])}
    },
    "noDestData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无数据，请选择目的地"])},
    "days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "天"])}
  },
  "tourListsort": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格由低到高"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天数由少到多"])}
  },
  "tourDetail": {
    "departureLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出发地"])},
    "bookNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即预定"])},
    "menu": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品介绍"])},
      "travel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程明细"])},
      "package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期/套餐"])},
      "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅游须知"])}
    },
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品编号"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程天数"])},
    "highlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程亮点"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简要行程"])},
    "returnList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回列表"])},
    "itinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日行程"])},
    "travelDayth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAY"])}
  }
}