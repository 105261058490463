import { useStorage } from '@vueuse/core';
import _ from 'lodash';
import { getUserInfo } from '@/api/wxAuth';
export const PLATFORM_TYPE = {
  WXWEB: 'wxWeb',
  MINIPROGRAM: 'miniProgram'
};
export const useWxUserStore = defineStore('wxUser', () => {
  const openId = useStorage('openId', '', sessionStorage);
  const userInfo = useStorage('userInfo', {}, sessionStorage);
  const userType = useStorage('userType', {}, sessionStorage);
  const platform = useStorage('platform', {}, sessionStorage);
  const router = useRouter();
  const isLogin = computed(() => {
    return userInfo.value && (userInfo.value.AccountCode || userInfo.value.code);
  });
  function setOpenId(id) {
    openId.value = id;
  }
  function setPlatform(p) {
    platform.value = p;
  }
  function getWxUserInfo() {
    const params = {
      openId: openId.value,
      source: 'gttty'
    };
    if (platform.value === PLATFORM_TYPE.WXWEB) {
      params['type'] = 'openid';
    } else if (platform.value === PLATFORM_TYPE.MINIPROGRAM) {
      params['type'] = 'unionid';
    }
    return getUserInfo(params).then(res => {
      userInfo.value = res;
      return res;
    }).catch(err => {
      console.error(err);
    });
  }
  function setUser(user) {
    userInfo.value = user;
    userType.value = 'AGENT';
  }
  function getSubmitUserInfo() {
    if (_.isEmpty(userInfo.value)) return null;
    const data = {};
    if (userInfo.value.AccountCode) data['account'] = userInfo.value.AccountCode;else if (userInfo.value.code) data['account'] = userInfo.value.code;
    if (platform.value === PLATFORM_TYPE.WXWEB) {
      data['openId'] = userInfo.value.OpenID;
    } else if (platform.value === PLATFORM_TYPE.MINIPROGRAM) {
      data['openId'] = userInfo.value.OpenID;
    } else {
      data['openId'] = userInfo.value.email;
    }
    return data;
  }
  function getAccountName() {
    if (userInfo.value && (userInfo.value.AccountCode || userInfo.value.firstName)) return userInfo.value.AccountCode || userInfo.value.firstName;
    return '';
  }
  function getLoginID() {
    if (!userInfo.value) return '';
    if (userInfo.value && userInfo.value.email) return userInfo.value.email;else if (userInfo.value.OpenID) return userInfo.value.OpenID;
    return '';
  }
  function logout() {
    openId.value = '';
    userInfo.value = null;
    userType.value = '';
    platform.value = '';
    router.replace('/');
  }
  const isAccountUser = computed(() => {
    return userInfo.value.code || userInfo.value.AccountCode;
  });
  return {
    openId,
    userInfo,
    platform,
    isLogin,
    isAccountUser,
    setOpenId,
    getLoginID,
    getWxUserInfo,
    getSubmitUserInfo,
    setUser,
    getAccountName,
    logout,
    setPlatform
  };
});