import router from './index';
// import { useUserStore } from '@/store/user';
// import { useAppStore } from '@/store/app';
import { useBookStore } from '@/store/book';
import { useWxUserStore } from '@/store/wxUser';

// import { isMiniProgram, isWxWeb } from '@/utils/browser';
import { useWxUser } from '@/composables/app/useWxUser';

const authRoutes = ['/login', '/register', '/400'];

router.beforeEach(async (to, from, next) => {
  // const userStore = useUserStore();
  const wxUserStore = useWxUserStore();
  const bookStore = useBookStore();

  const { initWxUser } = useWxUser();

  // await appStore.initApp();

  if (authRoutes.includes(to.path)) {
    next();
    return false;
  }

  if (to.path === '/orders' && !wxUserStore.isLogin) {
    const res = await initWxUser(to);
    if (res) {
      next();
      return false;
    }
  }

  if (to.meta.requiresAuth && !wxUserStore.isLogin) {
    next({
      name: 'login'
      // query: {
      //   to: encodeUrl(path),
      // },
    });
    return;
  }

  // if (!userStore.isLoggedIn) {
  //   next('/login');
  //   return false;
  // }

  // book page guard: redirect while no book routing data
  if (to.path === '/book' && !bookStore.isBookable()) {
    next('/');
    return false;
  }

  next();
});
