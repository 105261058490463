import axios from 'axios';

const axInstance = axios.create({
  timeout: 50000
});

axInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axInstance.interceptors.response.use(
  (response) => {
    if (response.data.hop2WsError && response.data.hop2WsError) {
      return Promise.reject(response.data.hop2WsError.errors[0]);
    }
    // 揣兜api reponse
    if (response.data.status && response.data.status != 0) {
      return Promise.reject(response.data);
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const get = (url, config) => {
  return axInstance.get(url, config);
};

export const post = (url, data) => {
  return axInstance.post(url, data);
};
export const jtPost = (url, data) => {
  return axInstance.post(url, data, {
    headers: {
      'content-Type': 'text/plain'
    }
  });
};
