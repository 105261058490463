export function useFlightOneRound(props, info = {
  departure: '',
  arrival: '',
  dateOne: null,
  dateRound: []
}) {
  const departure = ref(info.departure);
  const arrival = ref(info.arrival);
  const flightDateOneway = ref(info.dateOne);
  const flightDateRound = ref(info.dateRound);
  function getSearchData() {
    const data = {
      departureAirport: departure.value,
      destinationAirport: arrival.value
    };

    // roundtrip date
    if (toValue(props).roundtrip) {
      data['departureDate'] = flightDateRound.value[0];
      data['returnDate'] = flightDateRound.value[1];
    } else {
      // oneway date
      const date = flightDateOneway.value;
      data['departureDate'] = date;
    }
    return data;
  }

  // simple validate: validate if it is empty
  function validate() {
    // const isPass = validateFormRef.value.validate();

    let isPass = true;
    if (!departure.value) {
      isPass = false;
    }
    if (!arrival.value) {
      isPass = false;
    }
    if (toValue(props).roundtrip) {
      if (flightDateRound.value.length !== 2) {
        isPass = false;
      }
    } else {
      if (!flightDateOneway.value) {
        isPass = false;
      }
    }
    return isPass;
  }
  watch(() => props.value ? props.value.roundtrip : '', (val, oldVal) => {
    if (!oldVal && val) {
      flightDateRound.value = [];
    }
  }, {
    deep: true
  });
  return {
    departure,
    arrival,
    flightDateOneway,
    flightDateRound,
    getSearchData,
    validateSearch: validate
  };
}