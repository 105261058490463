import { createEnum } from '@/utils/util';

export const TRIP_TYPE = createEnum([
  { value: 'oneway', label: 'One-way', name: 'ONEWAY' },
  { value: 'roundtrip', label: 'Round-trip', name: 'ROUNDTRIP' },
  { value: 'multicity', label: 'Multi-city', name: 'MULTICITY' }
]);

export const TRIP_TYPE_VALUE = createEnum([
  { value: 1, label: 'One-way', name: 'oneway' },
  { value: 2, label: 'Round-trip', name: 'roundtrip' },
  { value: 3, label: 'Multi-city', name: 'multicity' }
]);

export const TRIP_TYPE_ENUM = {
  ONEWAY: 'oneway',
  ROUNDTRIP: 'roundtrip',
  MULTICITY: 'multicity'
};

export const CABIN_TYPE = createEnum([
  { value: 'A', label: 'All', name: 'ALL' },
  { value: 'E', label: 'Economy', name: 'ECONOMY' },
  { value: 'P', label: 'Premiun Economy', name: 'PREMIUN' },
  { value: 'B', label: 'Business', name: 'BUSINESS' },
  { value: 'F', label: 'First Class', name: 'FIRSTCLASS' }
]);

export const CABIN_TYPE_VALUE = createEnum([
  { value: 0, label: 'All', name: 'ALL' },
  { value: 1, label: 'Economy', name: 'ECONOMY' },
  { value: 4, label: 'Premiun Economy', name: 'PREMIUN' },
  { value: 2, label: 'Business', name: 'BUSINESS' },
  { value: 3, label: 'First Class', name: 'FIRSTCLASS' }
]);

export const CABIN_TYPE_ENUM = {
  ALL: 'A',
  ECONOMY: 'E',
  PREMIUN: 'P',
  BUSINESS: 'B',
  FIRSTCLASS: 'F'
};

export const MAX_NUM_PASSENGERS = 9;

export const PASSENGER_TYPE = {
  ADULT: 'adult',
  YOUTH: 'youth',
  CHILD: 'child',
  INFANT: 'infant'
};

export const PASSENGER_ENUM = {
  [PASSENGER_TYPE.ADULT]: {
    text: 'adult',
    texts: 'adults',
    tip: '(ages 12 and up)'
  },
  [PASSENGER_TYPE.YOUTH]: {
    text: 'youth',
    texts: 'youths',
    tip: '(ages 12-17)'
  },
  [PASSENGER_TYPE.CHILD]: {
    text: 'child',
    texts: 'children',
    tip: '(ages 2-11)'
  },
  [PASSENGER_TYPE.INFANT]: {
    text: 'infant',
    texts: 'infants',
    tip: '(under 2)'
  }
};

export const ENABLE_PASSENGER_TYPE = [
  PASSENGER_TYPE.ADULT,
  PASSENGER_TYPE.CHILD
];

export const PASSENGER_TYPE_NUM = {
  adult: 0,
  child: 1
};

export const PASSENGER_TYPE_VALUE = createEnum([
  { value: 0, label: 'adult', name: 'adult' },
  { value: 1, label: 'child', name: 'child' }
]);

export const BRAND_ATTRS = {
  EXTRA_SPACIOUS_SEAT: 'Extra-spacious seat',
  REFUNDABLE: 'Refundable',
  NO_CHANGE_FEES: 'No change fees',
  ONE_CARRYON_BAG_INCLUDED: '1 carry-on bag included',
  CHOOSE_YOUR_SEAT: 'Choose your seat',
  UPGRADES_AVAILABLE: 'Upgrades available',
  NON_REFUNDABLE: 'Non-refundable',
  ROOMIER_SEAT: 'Roomier seat',
  CARRYON_BAG_INCLUDED: 'Carry-on bag included',
  CHECKED_BAG_INCLUDED: 'Checked bag included',
  CHOOSE_SEAT_FOR_A_FEE: 'Choose your seat for a fee',
  NO_UPGRADES: 'No Upgrades',
  CHANGES_NOT_ALLOWED: 'Changes not allowed',
  CHANGE_ALLOWED_FOR_FEE: 'Changes allowed for a fee',
  CHECKED_BAG_NOT_INCLUDED: 'Checked bag not included'

  // CARRY_ON: 'CarryOn',
  // REFUND: 'Refund',
  // MEALS: 'Meals',
  // REBOOKING: 'Rebooking',
  // CHECKED_BAG: 'CheckedBag',
  // SEAT_ASSIGNMENT: 'SeatAssignment',
  // WIFI: 'Wifi',
  // PREMIUM_SEAT: 'PremiumSeat',
  // LIEFLAT_SEAT: 'LieflatSeat',
  // PERSONAL_ITEM: 'PersonalItem',
  // MILEAGE_ACCRUAL: 'Mileage Accrual'
};

export const BAG_TYPES = {
  BAGGAGES: 'baggages',
  CARRYON: 'carry-on'
};

export const GDS_TITLE = {
  F: 'Airline NDC',
  M: 'Travelport GDS',
  D: 'Amadeus NDC',
  N: 'Travelport NDC'
};

export const FARE_TYPES = {
  PUBLISHED: 'published fare',
  COMMISSION_PUBLISHED: 'commission published fare',
  SR_OR_BULK: 'private bulk or nett fare'
};

export const FARE_PRIVATE_TYPES = {
  COMM: 'COMM',
  SR: 'SR',
  BULK: 'BULK'
};
