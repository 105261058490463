const MOCK_TAG = '/mock';

export default function mockApi(config) {
  if (config.isMock) {
    config.timeout = 5000;
    config.url = MOCK_TAG + config.url;
  }

  return config;
}
