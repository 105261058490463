import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faXmark,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPlaneArrival,
  faPlaneDeparture,
  faCalendarDays,
  faSliders,
  faCaretLeft,
  faCaretRight,
  // faLightbulb,
  faLocationDot,
  faClock,
  faCircleChevronUp,
  faCircleChevronDown,
  faSuitcase,
  faSuitcaseRolling,
  faCircleExclamation,
  faUser,
  faStar,
  faMobileScreenButton,
  faPlane,
  faBed,
  faCircleUser,
  faFileInvoiceDollar,
  faCirclePlus,
  faDollarSign,
  faCheck,
  faArrowRight,
  faExchangeAlt,
  faFileAlt,
  faUmbrellaBeach
} from '@fortawesome/free-solid-svg-icons';

import {
  faCreditCard,
  faGem,
  faLightbulb,
  faAddressBook
} from '@fortawesome/free-regular-svg-icons';

export default {
  install: (app) => {
    library.add(
      faXmark,
      faChevronUp,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faPlaneArrival,
      faPlaneDeparture,
      faCalendarDays,
      faSliders,
      faCaretLeft,
      faCaretRight,
      faLightbulb,
      faLocationDot,
      faClock,
      faCircleChevronUp,
      faCircleChevronDown,
      faSuitcase,
      faSuitcaseRolling,
      faCircleExclamation,
      faUser,
      faStar,
      faMobileScreenButton,
      faPlane,
      faBed,
      faCircleUser,
      faFileInvoiceDollar,
      faCirclePlus,
      faDollarSign,
      faCheck,
      faCreditCard,
      faGem,
      faAddressBook,
      faArrowRight,
      faExchangeAlt,
      faFileAlt,
      faUmbrellaBeach
    );

    app.component(FontAwesomeIcon.name, FontAwesomeIcon);
  }
};
