import LayoutOutward from '@/layout/LayoutOutward.vue';

export const accurateRouters = {
  path: '/accurate',
  component: LayoutOutward,
  children: [
    {
      path: 'pricing',
      name: 'PricingSearch',
      component: () => import('../views/accurateSearch/pricing/PricingView.vue')
    },
    {
      path: 'flights',
      name: 'AccurateFlights',
      component: () =>
        import(
          '../views/accurateSearch/searchFlight/AccurateSearchFlightView.vue'
        )
    },
    {
      path: 'book',
      name: 'AccurateBook',
      component: () =>
        import('../views/accurateSearch/bookFlight/AccurateBookFlightView.vue')
    },
    {
      path: 'bookResult/:orderNo',
      name: 'AccurateBookResult',
      component: () =>
        import(
          '../views/accurateSearch/bookFlightResult/AccurateBookFlightResultView.vue'
        )
    }
  ]
};
