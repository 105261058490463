import { CABIN_TYPE, CABIN_TYPE_ENUM } from '@/constants/flight';
import { useUserStore } from '@/store/user';
import { useFlightFunction } from './useFlightFunction';

/**
 * 0 - 不限制 All
 * 1 - 经济舱 Economy
 * 2 - 豪华经济舱 Premiun Economy
 * 3 - 商务舱 Business
 * 4 - 头等舱 First Class
 */
const cabinAdapter = ['A', 'E', 'P', 'B', 'F'];
export function useAuthLimit() {
  const {
    t
  } = useI18n({
    useScope: 'global'
  });
  const userStore = useUserStore();
  /**
   * 根据用户信息中舱位限制数据 筛选可用舱位
   * LimitCabin:
   * 0 - 不限制 All
   * 1 - 经济舱 Economy
   * 2 - 豪华经济舱 Premiun Economy
   * 3 - 商务舱 Business
   * 4 - 头等舱 First Class
   */
  const limitedCabinList = computed(() => {
    const LimitCabin = userStore.LimitCabin;
    return CABIN_TYPE().filter(fc => {
      if (LimitCabin === 0) return true;
      if (fc.value === 'A') return false;
      cabinAdapter[LimitCabin];
      if (fc.value === cabinAdapter[LimitCabin]) return true;
      return false;
    }).map(c => {
      return {
        ...c,
        label: t(`cabin.${c.value}`)
      };
    });
  });
  const cabinDefaultValue = computed(() => {
    const LimitCabin = userStore.LimitCabin;
    if (LimitCabin === 0) return CABIN_TYPE_ENUM.ALL;
    return cabinAdapter[LimitCabin];
  });
  function getListFilterByLimitAmount(list) {
    const amount = userStore.LimitAmount;
    const {
      getTotalPrice
    } = useFlightFunction();
    return list.filter(l => {
      if (amount === 0) return true;
      const total = getTotalPrice(l, {
        AdultsNum: 1,
        ChildNum: 0
      });
      return amount >= total;
    });
  }
  return {
    limitedCabinList,
    cabinDefaultValue,
    getListFilterByLimitAmount
  };
}