import { addDevPrefix, urlAddPrefix } from '../util';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_API_GTTB2B_FEIQUANQIU
    : '';

const url_prefix = addDevPrefix('gttb2b');

function baseRequestInterceptor() {
  return [(config) => config, (error) => Promise.reject(error)];
}

function contentTypeInterceptor() {
  return (config) => {
    config.headers['content-Type'] = 'application/json';

    return config;
  };
}

function baseURLInterceptor() {
  return (config) => {
    config.baseURL = baseURL;

    return config;
  };
}

function urlInterceptor() {
  return (config) => {
    return urlAddPrefix(config, url_prefix);
  };
}

function responseInterceptor() {
  return [
    (response) => {
      return response.data;
    },
    (error) => {
      return Promise.reject(error);
    }
  ];
}

export default {
  request: [
    baseRequestInterceptor,
    contentTypeInterceptor,
    baseURLInterceptor,
    urlInterceptor
  ],
  response: [responseInterceptor]
};
