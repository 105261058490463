// import { transformMDY } from '@/utils/date-util';
// import {
//   TRIP_TYPE_VALUE,
// } from '@/constants/flight';

function _getBookDataDefault() {
  return {
    tripType: '',
    routing: null,
    baseRouting: null,
    adults: 0,
    children: 0,
    departureAirport: '',
    destinationAirport: '',
    departureDate: '',
    returnDate: '',
    trips: '',
    fare: null,
    cid: ''
  };
}
export const useBookStore = defineStore('book', () => {
  const bookTag = ref('book');
  const bookingData = ref(_getBookDataDefault());
  const brands = shallowRef([]);

  /**
   * 机票列表预定操作
   * @param {*} param0
   */
  function confirmBook({
    tripType,
    routing,
    adults,
    children,
    departureAirport,
    destinationAirport,
    departureDate,
    returnDate,
    trips,
    fare,
    cid
  }) {
    bookingData.value.tripType = tripType;
    bookingData.value.routing = routing;
    bookingData.value.baseRouting = routing;
    bookingData.value.adults = adults || 1;
    bookingData.value.children = children || 0;
    bookingData.value.departureAirport = departureAirport || '';
    bookingData.value.destinationAirport = destinationAirport || '';
    bookingData.value.departureDate = departureDate || '';
    bookingData.value.returnDate = returnDate || '';
    bookingData.value.trips = trips || '';
    bookingData.value.fare = fare;
    bookingData.value.cid = cid;
  }
  function resetBook() {
    bookingData.value = _getBookDataDefault();
  }
  function isBookable() {
    return Boolean(bookingData.value.routing);
  }
  function updateBookRouting(routing) {
    bookingData.value.routing = routing;
  }
  function setFareBrands(group) {
    brands.value = group;
  }
  return {
    bookTag,
    bookingData,
    brands,
    isBookable,
    confirmBook,
    resetBook,
    updateBookRouting,
    setFareBrands
  };
});