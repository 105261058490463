import { API_URL } from './config';
import api from './lib';

export const login = (data) => {
  return api.authRequest.authPost(API_URL.auth.login, data);
};

export const register = (data) => {
  return api.authRequest.authPost(API_URL.auth.register, data);
};
