import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content-flight"
};
const _hoisted_2 = {
  class: "big_images"
};
const _hoisted_3 = {
  action: "",
  class: "index_search"
};
const _hoisted_4 = {
  class: "search_box"
};
const _hoisted_5 = {
  key: 1,
  class: "p-y-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
  const _component_search_flights = _resolveComponent("search-flights");
  const _component_search_tour = _resolveComponent("search-tour");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("article", _hoisted_2, [_createElementVNode("h3", null, _toDisplayString(_ctx.$t('home.bookTitle')), 1), _createElementVNode("form", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("button", {
    class: _normalizeClass(["btn search_btn capitalize", {
      active: $data.selectFormType === $data.formTypes.FLIGHT
    }]),
    type: "button",
    onClick: _cache[0] || (_cache[0] = $event => $options.handleChangeForm($data.formTypes.FLIGHT))
  }, [_createVNode(_component_font_awesome_icon, {
    icon: "fa-solid fa-plane"
  }), _createTextVNode(" " + _toDisplayString(_ctx.$t('common.flight')), 1)], 2), _createElementVNode("div", null, [$data.selectFormType === $data.formTypes.FLIGHT ? (_openBlock(), _createBlock(_component_search_flights, {
    key: 0
  })) : $data.selectFormType === $data.formTypes.TOUR ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_search_tour)])) : _createCommentVNode("", true)])])])])]);
}