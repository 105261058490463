import { addDevPrefix, urlAddPrefix } from '../util';

const baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_API_GTT_BOOK_HOST
    : '';

// const url_prefix = addDevPrefix('wxflight');
const url_prefix = addDevPrefix('gtapi');

function baseRequestInterceptor() {
  return [(config) => config, (error) => Promise.reject(error)];
}

function contentTypeInterceptor() {
  return (config) => {
    config.headers['content-Type'] = 'application/json';

    return config;
  };
}

function baseURLInterceptor() {
  return (config) => {
    config.baseURL = baseURL;

    return config;
  };
}

function urlInterceptor() {
  return (config) => {
    return urlAddPrefix(config, url_prefix);
  };
}

function responseInterceptor() {
  return [
    (response) => {
      const res =
        typeof response.data === 'string'
          ? JSON.parse(response.data)
          : response.data;

      // if (res.Status && res.Status != 0) {
      //   return Promise.reject(res);
      // }
      return res;
    },
    (error) => {
      return Promise.reject(error);
    }
  ];
}

export default {
  request: [
    baseRequestInterceptor,
    contentTypeInterceptor,
    baseURLInterceptor,
    urlInterceptor
  ],
  response: [responseInterceptor]
};
