export default {
  "common": {
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "flight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["flight"])},
    "NoMatchedAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matched airport"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAIL"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["book"])},
    "cabin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabin"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "noFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is currently no flight information available"])},
    "noFilteredFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is currently no suitable flight information available"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
    "layover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["layover"])},
    "flightTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight Time"])},
    "fareBasis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare Basis "])},
    "bookingClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Class"])},
    "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["arrival"])},
    "layoverTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["layover time"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
    "perPassenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per person"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selected"])},
    "requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requesting..."])},
    "systemBusy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The system is busy, please try again later"])},
    "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["searching..."])},
    "aircraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aircraft"])},
    "priceBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["base"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accept"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])}
  },
  "dateTime": {
    "duration_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "d"])},
    "duration_hour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "h"])},
    "duration_minute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "m"])},
    "duration_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "s"])}
  },
  "flightSearch": {
    "departingFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departing from"])},
    "goingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Going to"])},
    "departReturnDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departing - Returning"])},
    "departDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departing"])},
    "cabinClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabin Class"])},
    "addAnotherFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another flight"])},
    "moreAirlineFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More Flights At This Airline"])},
    "morePriceFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More Flights At This Price"])},
    "stopText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nonstop"]), _normalize(["1 stop"]), _normalize([_interpolate(_named("count")), " stops"])])},
    "travelerNum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no traveler"]), _normalize(["1 traveler"]), _normalize([_interpolate(_named("count")), " travelers"])])},
    "searchEmptyTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the search information is complete"])}
  },
  "flightRule": {
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "refundPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund fee"])},
    "changePenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change fee"])},
    "endorsementPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarantee deduction fee"])},
    "luggageRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baggage allowance"])},
    "luggageDetail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("no")), " itinerary"])},
    "luggageNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no checked-in carry-on"])},
    "luggageAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["allow carry-on up to ", _interpolate(_named("count"))])},
    "luggageAllowPiecesText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow up to ", _interpolate(_named("count")), " pieces of luggage to be carried"])},
    "regulationsAccording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["According to airline regulations"])},
    "baggagesAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow checked baggage up to ", _interpolate(_named("count"))])},
    "baggagesAllowPiecesText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow check-in of up to ", _interpolate(_named("count")), " pieces of luggage"])},
    "baggagesAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow checked baggage"])},
    "baggagesNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No checked baggage allowed"])},
    "carryonAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow carry on luggage up to ", _interpolate(_named("count"))])},
    "carryonAllowPiecesText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow up to ", _interpolate(_named("count")), " pieces of carry on luggage"])},
    "carryonAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow carry on luggage"])},
    "carryonNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No carry on luggage allowed"])},
    "baggagesAllowDimensionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow check-in of up to ", _interpolate(_named("count")), " of luggage."])},
    "carryonAllowDimensionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow carry on luggage up to ", _interpolate(_named("count")), "."])},
    "baggages": {
      "allowPiecePerWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow checked baggage up to ", _interpolate(_named("piece")), " pieces,", _interpolate(_named("weight")), "."])},
      "allowPiece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow checked baggage up to ", _interpolate(_named("count")), " pieces."])},
      "allowWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow checked baggage up to ", _interpolate(_named("weight")), "."])},
      "allowCommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow checked baggage up to ", _interpolate(_named("text"))])},
      "notAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No checked baggage allowed"])}
    },
    "carryon": {
      "allowPiecePerWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow carry on luggage up to ", _interpolate(_named("piece")), " pieces,", _interpolate(_named("weight")), "."])},
      "allowPiece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow carry on luggage up to ", _interpolate(_named("count")), " pieces."])},
      "allowWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow carry on luggage up to ", _interpolate(_named("weight")), "."])},
      "allowCommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Allow carry on luggage up to ", _interpolate(_named("text"))])},
      "notAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No carry on luggage allowed"])}
    },
    "baggagesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baggage allowance"])},
    "carryonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CarryOn allowance"])},
    "unitPiece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["0 piece"]), _normalize(["1 piece"]), _normalize([_interpolate(_named("count")), " pieces"])])}
  },
  "tripType": {
    "oneway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-way"])},
    "roundtrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round-trip"])},
    "multicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-city"])}
  },
  "passenger": {
    "adult": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adult"])},
      "num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 adult"]), _normalize([_interpolate(_named("count")), " adults"])])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ages 12 and up)"])}
    },
    "child": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child"])},
      "num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 child"]), _normalize([_interpolate(_named("count")), " children"])])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ages 2-11)"])}
    }
  },
  "cabin": {
    "A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
    "P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premiun Economy"])},
    "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Class"])}
  },
  "cabinVal": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economy"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Class"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premiun Economy"])}
  ],
  "tips": {
    "passengerLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You may only book a flight for up to ", _interpolate(_named("num")), " travelers."])},
    "seatLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("text")), " tickets left"])},
    "seatLeftOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 ticket left"])},
    "seatLeftEnough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are plenty of seats available at this price"])}
  },
  "filter": {
    "group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group by"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airlines"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
      ]
    },
    "stop": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nonstop"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 stop"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 or more stops"])}
      ]
    },
    "airline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airline"])},
      "options": [
        
      ]
    },
    "time": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure Time"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival Time"])}
      ],
      "tips": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited"])}
      ]
    },
    "arrPort": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival port"])}
    },
    "depPort": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure port"])}
    },
    "channel": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])}
    },
    "fare": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fare type"])}
    },
    "time2": {
      "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afternoon"])},
      "arrivalTimeInCity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["arrival time in ", _interpolate(_named("city"))])},
      "departureTimeInCity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["departure time in ", _interpolate(_named("city"))])},
      "earlyMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["early morning"])},
      "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evening"])},
      "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["morning"])}
    }
  },
  "sort": {
    "cheapest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheapest"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("num"))])}
    },
    "earliest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earliest"])}
    },
    "fastest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastest"])}
    }
  },
  "home": {
    "bookTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book your flight with confidence"])},
    "findYourFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your flight"])}
  },
  "book": {
    "flightDetailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight Details"])},
    "summaryCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of charge"])},
    "totalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "flightInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight Information"])},
    "departureInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure Information"])},
    "returnInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Information"])},
    "passengerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger"])},
    "paymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Info"])},
    "payTypeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit or Debit Card"])},
    "cardHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info of Card Holder"])},
    "Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
    "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Info"])},
    "bookConfirmDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the Change and Cancellation Rules for this purchase and to the ForerunnerTravel"])},
    "confirmTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must check the box above agreeing to the Fare Rules and Terms of Use"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
    "noDataToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a itinerary for booking"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "fareSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare Selection"])},
    "seatmapError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetching date error"])},
    "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seatmap"])},
    "seatsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seatmap"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "paynowOrReserveTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay now or reserve"])},
    "prOptionPayNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'd like to enter payment information now"])},
    "prOptionReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'd like to book this now, and pay later"])},
    "offerExpiredTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The offer has expired, please return and search again!"])},
    "prepareBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparing for reservation..."])},
    "fareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare"])},
    "flightTripIndexTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["itinerary ", _interpolate(_named("index"))])},
    "seatTotalPriceTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total price for ", _interpolate(_named("count")), " seats"])},
    "commissionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission"])}
  },
  "passengerForm": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "firstNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid first name"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle name"])},
    "middleNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid middle name"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "lastNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid Last/Family/Surname"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
    "birthdayPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid Birthday"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "Femal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "cardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card type"])},
    "cardNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport numder"])},
    "cardNumPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid ID numder"])},
    "cardExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport Expiration"])},
    "cardExpiredPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid Expiration Date"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "cardIssuePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Issue Country"])},
    "infoTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The traveler's name must match the government-issued identification document intended to be used during travel."])},
    "knowTravellerFrequentFlyerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequent flyer number"])},
    "knowTravellerFrequentFlyerProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequent flyer program"])},
    "knowTravellerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Known traveller number"])},
    "knowTravellerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards Program & Known Traveller"])}
  },
  "cardType": {
    "P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passport"])}
  },
  "payment": {
    "creditCardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Type"])},
    "creditCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Number"])},
    "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
    "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "cardVerificationNumberPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of issuing bank"])},
    "bankTollFreeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Toll Free Number"])},
    "relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relationship with cardholders"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardholder's phone number"])},
    "relationships": {
      "Spouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spouse"])},
      "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child"])},
      "Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
      "Relative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relative"])},
      "Friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend"])},
      "Self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self"])},
      "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post code"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "paymentTypeCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Payment"])}
  },
  "contact": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "addressPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid Address"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "postcodePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid Postcode"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
    "mobilePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a valid phone number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "copyTraveller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact person name synchronized with the first passenger"])},
    "nameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["full name"])}
  },
  "loadingText": {
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating Orders... "])},
    "orderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking successful! "])},
    "paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order payment in progress..."])},
    "issueTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the process of issuing tickets..."])},
    "issueSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket issued successfully"])}
  },
  "order": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Info"])},
    "summaryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Summary"])},
    "myOrderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my orders"])},
    "orderListFilterDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "orderSearchBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "status_content_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is to confirm that your reservation has been cancelled and no charge has been applied. In accordance with our Company Policies and standards, aimed at maintaining maximum Customer security, we can assure you that all your personal and card information has been deleted from our system.\nWe are committed to maintaining the highest possible Customer and Security Service standards and looking forward to serving you again."])},
    "status_content_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your booking is confirmed and issued. We wish you a wonderful flight."])},
    "status_content_paymenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have opened a payment channel for you. Please click the payment button to make the payment. Please make the payment within 30 minutes. If it exceeds the deadline, it will be automatically cancelled. Once the payment is confirmed, it cannot be withdrawn."])},
    "status_content_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your order and we are now processing it. We will send you your booking confirmation and your e-ticket soon."])},
    "status_title_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation Confirmation"])},
    "status_title_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight Confirmation"])},
    "status_title_paymenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservation is pending payment"])},
    "status_title_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservation is processing"])},
    "ticket_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending..."])},
    "status_title_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your reservation has been paid"])},
    "orderDetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order detail"])},
    "order_cancel_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking cancellation failed"])},
    "order_new_not_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the order details cannot be loaded for a long time, please check on the order page later."])},
    "toOrderDetail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Link to ", _interpolate(_list(0))])},
    "sendItinerary_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fail in send"])},
    "sendItinerary_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent successfully"])},
    "receivingEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving email"])},
    "receivingEmailRequred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the receiving email address"])},
    "orderPriceSummaryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bill details"])},
    "orderPriceTotalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "orderListActionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select your action"])},
    "orderListActionChangeFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change flight"])},
    "orderListActionChangeSeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change seats"])},
    "orderListActionSendItinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send itinerary"])},
    "orderListActionViewTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view/manage trip"])},
    "orderPaySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment successful"])},
    "status_title_issued_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ticket issuance failed"])}
  },
  "responseMsg": {
    "1050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price you have selected is no longer available. Please search again"])},
    "1055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel information error, please search again"])},
    "1056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger information error"])},
    "1058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information error"])},
    "1059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The itinerary price has changed, please search again"])},
    "1060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order failed, please try again later"])},
    "1061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order failed, please try again later"])},
    "1062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The validity period of the document exceeds the limit range. Please fill it out again"])},
    "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data expired, please search again"])},
    "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel information error"])},
    "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabin or price changed, please search again"])},
    "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabin changes, please search again"])},
    "1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabin changes, please search again"])},
    "1209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System error, please try again later"])},
    "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter verification failed, please fill in again"])},
    "1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter verification failed, please fill in again"])},
    "1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter verification failed, please fill in again"])},
    "1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order does not exist, please search again"])},
    "1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order status is abnormal, please search again"])},
    "1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment deadline exceeded, please search again"])},
    "1407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment verification failed, please search again"])},
    "1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter verification failed, please try again later"])},
    "1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order does not exist, please search again"])},
    "1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not authorized to operate this order"])},
    "1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order is not in a waiting support status, please search again"])},
    "1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsupported payment methods"])},
    "1506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment deadline exceeded, please search again"])},
    "1507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment verification failed, please search again"])},
    "1510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient account balance"])},
    "1511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation failed, please search again"])},
    "1512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment processing in progress"])},
    "1513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order has paid"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation failed, please try again later"])},
    "priceChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The itinerary price has changed, please search again"])},
    "ticketPriceChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected itinerary price has changed, please search again"])},
    "invalidCraditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card unauthorized"])},
    "invalidCraditCardExpiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credit card expiration date"])},
    "invalidCraditCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card number is invalid"])},
    "invalidFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The itinerary is invalid, please search again"])},
    "noTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ticket"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log off"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "loginInfoError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account name or password error"])},
    "loginFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed, Please try again later."])},
    "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succeeded"])},
    "accountRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in your account number"])},
    "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in your password"])},
    "b2bUnRegisterNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not registered"])},
    "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
    "sendCodeFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send verification code"])},
    "sendCodeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully sent verification code"])},
    "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Code"])},
    "verificationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code error"])},
    "verificationCodeRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the verification code"])},
    "verificationCodeSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send verification code"])}
  },
  "validate": {
    "emptyTip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please fill in ", _interpolate(_named("key"))])},
    "passenger": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the passenger information is complete!"])}
    },
    "contact": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the contact information is complete!"])}
    },
    "payment": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the payment information is filled in completely"])}
    }
  },
  "seatmap": {
    "passengerText": {
      "ADT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult"])},
      "CHD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child"])}
    },
    "seatmapSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save seat information"])},
    "seatmapSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully saved seat information"])},
    "seatmapTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seat"])}
  },
  "service": {
    "serviceSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save service information"])},
    "serviceSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully saved service information"])},
    "serviceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel add-ones"])}
  },
  "bookPolicy": {
    "privacyPolicyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "restrictionRulesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare restrictions and rules"])},
    "restrictionRulesBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rule"])}
  },
  "exchange": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change"])},
    "changingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changing"])},
    "exchangeFeeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["airline fees"])},
    "exchangeFormInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm that the information is filled in completely"])},
    "exchangeRequestFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange failed"])},
    "tripInTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inbound"])},
    "tripIndexTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["trip ", _interpolate(_named("index"))])},
    "tripOutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["outbound"])},
    "exchangeDataInvalidTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid ticket exchange data!"])},
    "exchangeFareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fare"])},
    "exchangeSearchFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search failed"])},
    "exchangeSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight Exchanged"])},
    "exchangeSuccessTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your booking is confirmed and reissued. We wish you a wonderful flight."])}
  },
  "pricing": {
    "analyzingFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["request failed"])},
    "getUserFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed"])},
    "priceFormInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in the text"])}
  }
}