import { createPinia } from 'pinia';
import persistedstate from 'pinia-persistedstate';

const store = createPinia();
store.use(
  persistedstate({
    key: 'gtt_v3',
    paths: [
      'airports',
      'book.bookTag',
      'flight.sortParams',
      'user',
      'wxUser',
      'order',
      'accurate.tag'
    ] // Keep state, use module id, or state
  })
);

export default store;
