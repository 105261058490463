export const flightRouters = [
  {
    path: 'flights',
    name: 'flights',
    component: () => import('../views/flights/FlightsView.vue')
  },
  {
    path: 'flights-invoice',
    name: 'flightsWithInvoice',
    component: () => import('../views/flights/FlightsWithInvoiceView.vue')
  },
  // {
  //   path: 'seats',
  //   name: 'seats',
  //   component: () => import('../views/seats/SeatsView.vue')
  // },
  {
    path: 'book',
    name: 'book',
    component: () => import('../views/book/BookView.vue')
  },
  {
    path: 'book/result/:orderNo',
    name: 'bookResult',
    component: () => import('../views/book/BookResultView.vue')
  }
];
