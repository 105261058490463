import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");
  const _component_swiper = _resolveComponent("swiper");
  return _openBlock(), _createBlock(_component_swiper, _normalizeProps(_guardReactiveProps($props.options)), {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.slides, (slide, index) => {
      return _openBlock(), _createBlock(_component_swiper_slide, {
        key: index
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "item", _normalizeProps(_guardReactiveProps({
          item: slide,
          index
        })))]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 3
  }, 16);
}