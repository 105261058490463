import axios from 'axios';
import interceptors from './interceptors/gttb2bFeiquanqiu';
import _ from 'lodash';

const axInstance = axios.create({
  timeout: 30000
});

interceptors.request.forEach((interceptor) => {
  const toUseInterceptor = interceptor();
  if (_.isArray(toUseInterceptor)) {
    axInstance.interceptors.request.use(...toUseInterceptor);
  } else {
    axInstance.interceptors.request.use(toUseInterceptor);
  }
});

interceptors.response.forEach((interceptor) => {
  const toUseInterceptor = interceptor();
  if (_.isArray(toUseInterceptor)) {
    axInstance.interceptors.response.use(...toUseInterceptor);
  } else {
    axInstance.interceptors.response.use(toUseInterceptor);
  }
});
// axInstance.interceptors.response.use(
//   (response) => {
//     const res =
//       typeof response.data === 'string'
//         ? JSON.parse(response.data)
//         : response.data;

//     if (res.Status && res.Status != 0) {
//       return Promise.reject(res);
//     }
//     return res;
//   },
//   (error) => {
//     return Promise.reject(handleError(error));
//   }
// );

export const post = (url, data, config) => {
  return axInstance.post(url, data, {
    ...config
  });
};

export const get = (url, config) => {
  return axInstance.get(url, config);
};
