export const useAccurateStore = defineStore('accurate', () => {
  const tag = ref('accurate');
  const accountCode = ref('');
  const bookedBaseRouting = shallowRef(null);
  const bookedRouting = ref('');

  /**
   * passengers: {
      adults
      children
    },
    cid
    customerSessionId
   */
  const bookedData = shallowRef(null);

  /**
   * "customerSessionId": "aX7BnD0qYniKXjt",
        "confirmationCode": "XCA-083024-mkua-F-WDA",
        "gds": "F",
        "pcc": "A95R",
        "recordLocator": "BAU63Y",
        "airlineRecordLocator": "KCNQKO"
      .status
      paymentStatus
      cid: requestData.cid,
      passengers: requestData.passengers,
      contact: requestData.contact,
      routing: requestData.routing,
      priceSummary: toRaw(priceSummary.value)
   */
  const bookResultData = shallowRef(null);
  const isAccountUser = computed(() => {
    return !!accountCode.value;
  });
  function startBook(data) {
    const {
      routing,
      ...bookData
    } = data;
    bookedRouting.value = routing;
    bookedData.value = bookData;
    bookedBaseRouting.value = routing;
    accountCode.value = data.accountCode;
  }
  function updateRouting(routing) {
    bookedRouting.value = routing;
  }
  function setOrderedData(data) {
    bookResultData.value = data;
  }
  function getAccountName() {
    return accountCode.value;
  }
  return {
    tag,
    accountCode,
    bookedBaseRouting,
    bookedRouting,
    bookedData,
    bookResultData,
    isAccountUser,
    startBook,
    updateRouting,
    setOrderedData,
    getAccountName
  };
});