import * as authRequest from './authRequest';
import * as gtRequest from './gtRequest';
import * as request from './request';
import * as bookRequest from './bookRequest';
import * as gtBookRequest from './gtBookRequest';
import * as wxRequest from './wxRequest';
import * as exchangeRequest from './exchangeRequest';
import * as b2bRequest from './b2bRequest';
import * as accurateRequest from './accurateRequest';
import * as gttb2bFeiquanqiu from './gttb2bFeiquanqiu';

export default {
  authRequest,
  gtRequest,
  request,
  bookRequest,
  gtBookRequest,
  wxRequest,
  exchangeRequest,
  b2bRequest,
  accurateRequest,
  gttb2bFeiquanqiu
};
