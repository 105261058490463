import { getCurrencyconverter } from '@/api/app';
import { isBefore } from '@/utils/date-util';
import { useUserStore } from './user';
import { useStorage } from '@vueuse/core';
export const useAppStore = defineStore('app', () => {
  const userStore = useUserStore();

  // intercom - online service
  const intercomStatus = ref(false);
  function initIntercom({
    userId,
    name,
    email = 'muling@scholartrip.com'
  }) {
    if (process.env.NODE_ENV === 'development') return false;
    console.log('initIntercom');
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: process.env.VUE_APP_INTERCOM_APPID,
      user_id: userId,
      name,
      // Full name
      email,
      // the email for your user
      created_at: new Date().getTime() // Signup date as a Unix timestamp
    });
    window.Intercom('update');
    intercomStatus.value = true;
  }
  function closeInterCom() {
    if (process.env.NODE_ENV === 'development') return false;
    window.Intercom('shutdown');
    intercomStatus.value = false;
  }

  // currency
  const currency = useStorage('gtt-currency', {
    rates: [],
    updateDate: 0
  }, localStorage, {
    mergeDefaults: true
  });
  async function initApp() {
    // if currency out of expires
    if (isExpiresOutCurrency()) {
      currency.value.rates = [];
      try {
        const res = await getCurrencyconverter();
        if (res && res.rates) {
          currency.value.rates = res.rates;
          currency.value.updateDate = new Date();
        }
      } catch (err) {
        console.log('获取汇率数据失败！', err);
      }
    }
    if (userStore.isLoggedIn && !isIntercomInit()) {
      // initIntercom({
      //   userId: userStore.userId,
      //   name: userStore.userName
      // });
    }
  }
  function isIntercomInit() {
    return window.Intercom && intercomStatus.value;
  }
  function isExpiresOutCurrency() {
    const now = new Date();
    const oldTime = currency.value.updateDate;
    return isBefore(oldTime, now, 'day');
  }
  return {
    initIntercom,
    closeInterCom,
    initApp,
    currencyRates: currency.value.rates
  };
});