import { sum, multiply, toFixed } from '@/utils/math';

export function useFlightFunction() {
  function _calculatePriceWithTax(taxType, price, tax, num) {
    let total = 0;
    if (taxType === 0) {
      total = sum([total, multiply(price, num), multiply(tax, num)]);
    } else {
      total = sum([total, multiply(price, num)]);
    }

    return total;
  }

  function getTotalPrice(item, passengers) {
    const {
      adultTaxType,
      childTaxType,
      adultPrice,
      adultTax,
      childPrice,
      childTax
    } = item;

    const { adults, children } = passengers;

    const adultTotal = _calculatePriceWithTax(
      adultTaxType,
      adultPrice,
      adultTax,
      adults
    );
    const childTotal = _calculatePriceWithTax(
      childTaxType,
      childPrice,
      childTax,
      children
    );

    const total = sum([adultTotal, childTotal]);

    return toFixed(total, 2);
  }

  function getTotalDuration(segments) {
    // compute total duration
    return segments.reduce((total, cur) => {
      return total + cur.duration;
    }, 0);
  }

  function getPersonPrice(segment, personNum = 1) {
    const { taxType, price, tax } = segment;
    const total = _calculatePriceWithTax(taxType, price, tax, personNum);

    console.log(toFixed(total, 2));
    return toFixed(total, 2);
  }

  return {
    getTotalPrice,
    getTotalDuration,
    getPersonPrice
  };
}
