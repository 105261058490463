import { createI18n } from 'vue-i18n';
import { getNavigatorLanguage } from '../utils/lang';
function isComposer(instance, mode) {
  return mode === 'composition' && isRef(instance.locale);
}
export const LOCALES_LIST = [{
  text: 'English',
  value: 'en'
}, {
  text: '中文(简体)',
  value: 'zh-CN'
}, {
  text: '中文(繁體)',
  value: 'zh-TW'
}
// {
//   text: '日本語',
//   value: 'ja'
// },
// {
//   text: '한국어',
//   value: 'ko'
// },
// {
//   text: 'Tiếng Việt',
//   value: 'vi',
// },
// {
//   text: 'Français',
//   value: 'fr'
// }
];

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}
const defaultLang = getNavigatorLanguage().split('-');
let defaultLangStr = defaultLang[0];
if (defaultLang.includes('zh')) {
  defaultLangStr = defaultLang.join('-');
}
const defaultLocale = defaultLangStr || process.env.VUE_APP_I18N_LOCALE || 'en';
const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  // locale: 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
});
export function setLocale(locale) {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale;
  } else {
    i18n.global.locale = locale;
  }
}
export function setI18nLanguage(locale) {
  setLocale(locale);
  document.querySelector('html').setAttribute('lang', locale);
}
export function switchLanguage(locale) {
  // await loadLocaleMessages(locale);
  setI18nLanguage(locale);
}
export default i18n;