export default {
  "common": {
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登 入"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注 册"])},
    "flight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機票"])},
    "NoMatchedAirport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有匹配的機場"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇日期"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["篩選"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預定"])},
    "cabin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["艙位"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
    "noFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫無班機資訊"])},
    "noFilteredFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫無合適班機資訊"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加載中..."])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稅費"])},
    "layover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停留"])},
    "flightTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飛行時間"])},
    "fareBasis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票價基礎"])},
    "bookingClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["艙位"])},
    "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到达"])},
    "layoverTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停留时间"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无数据"])},
    "perPassenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每人"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已选择"])},
    "requesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求中…"])},
    "systemBusy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系統繁忙，請稍後再試"])},
    "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒐索中…"])},
    "aircraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機型"])},
    "priceBase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基礎票價"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒐索"])}
  },
  "dateTime": {
    "duration_day": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("days")), "天"])},
    "duration_hour": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("hours")), "小時"])},
    "duration_minute": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("minutes")), "分鐘"])},
    "duration_second": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("seconds")), "秒"])}
  },
  "flightSearch": {
    "departingFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出發地"])},
    "goingTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目的地"])},
    "departReturnDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出發日期 - 返回日期"])},
    "departDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出發日期"])},
    "cabinClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客艙等級"])},
    "addAnotherFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加另一個班機"])},
    "moreAirlineFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看此航空公司的更多班機"])},
    "morePriceFlights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看此價格的更多班機"])},
    "stopText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["直達"]), _normalize(["中轉1次"]), _normalize(["中轉", _interpolate(_named("count")), "次"])])},
    "travelerNum": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["無旅客"]), _normalize(["1名旅客"]), _normalize([_interpolate(_named("count")), "名旅客"])])},
    "searchEmptyTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請確認搜索資訊填寫完整"])}
  },
  "flightRule": {
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退改費"])},
    "refundPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退訂費"])},
    "changePenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改簽費"])},
    "endorsementPenalty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["擔保扣費"])},
    "luggageRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行李額"])},
    "luggageDetail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["第", _interpolate(_named("no")), "段行程"])},
    "luggageNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允許攜帶行李"])},
    "luggageAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許攜帶最多不超過", _interpolate(_named("count")), "的行李"])},
    "luggageAllowPiecesText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允许携带最多不超过", _interpolate(_named("count")), "件的行李"])},
    "regulationsAccording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按航司规定"])},
    "baggagesAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許托運最多不超過", _interpolate(_named("count")), "的行李"])},
    "baggagesAllowPiecesText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許托運最多不超過", _interpolate(_named("count")), "件的行李"])},
    "baggagesAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允許托運行李"])},
    "baggagesNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允許托運行李"])},
    "carryonAllowCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許攜帶最多不超過", _interpolate(_named("count")), "的隨身行李"])},
    "carryonAllowPiecesText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許攜帶最多不超過", _interpolate(_named("count")), "件的隨身行李"])},
    "carryonAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允許攜帶隨身行李"])},
    "carryonNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允許攜帶隨身行李"])},
    "baggagesAllowDimensionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許托運最大不超過 ", _interpolate(_named("count")), " 的行李"])},
    "carryonAllowDimensionText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許攜帶最大不超過 ", _interpolate(_named("count")), " 的隨身行李"])},
    "baggages": {
      "allowPiecePerWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許托運最多不超過 ", _interpolate(_named("piece")), " 件,", _interpolate(_named("weight")), " 的行李"])},
      "allowPiece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許托運最多不超過 ", _interpolate(_named("count")), " 件的行李"])},
      "allowWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許托運最多不超過 ", _interpolate(_named("weight")), " 的行李"])},
      "allowCommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許托運最多不超過", _interpolate(_named("text")), "的行李"])},
      "notAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允許托運行李"])}
    },
    "carryon": {
      "allowPiecePerWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許攜帶最多不超過 ", _interpolate(_named("piece")), " 件,", _interpolate(_named("weight")), " 的隨身行李"])},
      "allowPiece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許攜帶最多不超過 ", _interpolate(_named("count")), " 件的隨身行李"])},
      "allowWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許攜帶最多不超過 ", _interpolate(_named("weight")), " 的隨身行李"])},
      "allowCommon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["允許攜帶最多不超過", _interpolate(_named("text")), "的隨身行李"])},
      "notAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不允許攜帶隨身行李"])}
    },
    "baggagesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["托運行李額"])},
    "carryonTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隨身行李額"])},
    "unitPiece": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "件"])}
  },
  "tripType": {
    "oneway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單程"])},
    "roundtrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["往返"])},
    "multicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多程"])}
  },
  "passenger": {
    "adult": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成人"])},
      "num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1名成年人"]), _normalize([_interpolate(_named("count")), "名成年人"])])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(12歲及以上)"])}
    },
    "child": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童"])},
      "num": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1名兒童"]), _normalize([_interpolate(_named("count")), "名兒童"])])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(2-11歲)"])}
    }
  },
  "cabin": {
    "A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經濟艙"])},
    "P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高端經濟艙"])},
    "B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商務艙"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭等艙"])}
  },
  "cabinVal": [
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經濟艙"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高端經濟艙"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商務艙"])},
    (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭等艙"])}
  ],
  "tips": {
    "passengerLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["您最多只能為", _interpolate(_named("num")), "名旅客預訂班機。"])},
    "seatLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["還剩 ", _interpolate(_named("text")), " 張票"])},
    "seatLeftOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["還剩 1 張票"])},
    "seatLeftEnough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["座位充足"])}
  },
  "filter": {
    "group": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分組"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航空公司"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["價格"])}
      ]
    },
    "stop": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經停"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直達"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經停1次"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["經停超過2次"])}
      ]
    },
    "airline": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航空公司"])},
      "options": [
        
      ]
    },
    "time": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
      "options": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出發時間"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到達時間"])}
      ],
      "tips": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間不限"])}
      ]
    },
    "arrPort": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到达机场"])}
    },
    "depPort": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出发机场"])}
    },
    "channel": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["渠道"])}
    },
    "fare": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票价类型"])}
    },
    "time2": {
      "afternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下午"])},
      "arrivalTimeInCity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("city")), "到達時間"])},
      "departureTimeInCity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("city")), "出發時間"])},
      "earlyMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["淩晨"])},
      "evening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晚上"])},
      "morning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["早晨"])}
    }
  },
  "sort": {
    "cheapest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最便宜"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["$", _interpolate(_named("num"))])}
    },
    "earliest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最早"])}
    },
    "fastest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最快"])}
    }
  },
  "home": {
    "bookTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["滿懷信心地預訂班機"])},
    "findYourFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找您的班機"])}
  },
  "book": {
    "flightDetailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["班機詳情"])},
    "summaryCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["費用匯總"])},
    "totalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總計"])},
    "flightInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["班機資訊"])},
    "departureInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出發資訊"])},
    "returnInfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回資訊"])},
    "passengerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客"])},
    "paymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付資訊"])},
    "payTypeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡或借記卡"])},
    "cardHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持卡人資訊"])},
    "Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用"])},
    "contactTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯系資訊"])},
    "bookConfirmDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意此次購買的更改和取消規則。"])},
    "confirmTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必須勾選上面的核取方塊，同意票價規則和使用條款。"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["購買"])},
    "noDataToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇行程進行預定"])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加服务"])},
    "fareSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票价选择"])},
    "seatmapError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["座位数据获取失败!"])},
    "seats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选座"])},
    "seatsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择座位"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預定"])},
    "paynowOrReserveTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即或稍後支付"])},
    "prOptionPayNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即支付"])},
    "prOptionReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稍後支付"])},
    "offerExpiredTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠時間已過，請返回重新搜索！"])},
    "prepareBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預定準備中…"])},
    "fareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票價"])},
    "flightTripIndexTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["第", _interpolate(_named("index")), "段行程"])},
    "seatTotalPriceTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), "個座位總價"])},
    "commissionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["傭金"])}
  },
  "passengerForm": {
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
    "firstNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的名字"])},
    "middleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中名"])},
    "middleNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的中间名"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
    "lastNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的姓氏"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日"])},
    "birthdayPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的生日"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性别"])},
    "Femal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女"])},
    "Male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男"])},
    "cardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["證件類型"])},
    "cardNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["護照號碼"])},
    "cardNumPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的護照號碼"])},
    "cardExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["護照有效期"])},
    "cardExpiredPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的日期"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國籍"])},
    "cardIssuePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簽發國"])},
    "infoTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客姓名必須與政府簽發的打算在旅行期間使用的身份證明檔案相符。"])},
    "knowTravellerFrequentFlyerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计划编号"])},
    "knowTravellerFrequentFlyerProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常客计划"])},
    "knowTravellerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旅客编号"])},
    "knowTravellerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["飞行常客信息"])}
  },
  "cardType": {
    "P": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["護照"])}
  },
  "payment": {
    "creditCardType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡類型"])},
    "creditCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡號"])},
    "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效日期"])},
    "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月份"])},
    "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年份"])},
    "cardVerificationNumberPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入验证码"])},
    "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发卡行名称"])},
    "bankTollFreeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银行免费电话"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持卡人电话号码"])},
    "relationship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与持卡人关系"])},
    "relationships": {
      "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子女"])},
      "Friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["朋友"])},
      "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "Parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父母"])},
      "Relative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["亲属"])},
      "Self": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人"])},
      "Spouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配偶"])}
    },
    "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单地址"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家/地区"])},
    "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮编"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["县"])},
    "paymentTypeCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Payment"])}
  },
  "contact": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "addressPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的地址"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵編"])},
    "postcodePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的郵編"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手機號"])},
    "mobilePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的手機號"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵箱"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入有效的郵箱"])},
    "copyTraveller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系人员姓名与第一位客人同步"])},
    "nameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])}
  },
  "loadingText": {
    "createOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單創建中... "])},
    "orderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預定成功!"])},
    "paying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單支付中..."])},
    "issueTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票中..."])},
    "issueSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票成功"])}
  },
  "order": {
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單資訊"])},
    "summaryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單概要"])},
    "myOrderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
    "orderListFilterDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "orderSearchBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒐索"])},
    "status_content_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is to confirm that your reservation has been cancelled and no charge has been applied. In accordance with our Company Policies and standards, aimed at maintaining maximum Customer security, we can assure you that all your personal and card information has been deleted from our system.\nWe are committed to maintaining the highest possible Customer and Security Service standards and looking forward to serving you again."])},
    "status_content_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your booking is confirmed and issued. We wish you a wonderful flight."])},
    "status_content_paymenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已為您開通支付通道，請點擊支付按鈕進行支付，請您在30分鐘內支付，超時將自動取消，支付一經確認無法撤回。"])},
    "status_content_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your order and we are now processing it. We will send you your booking confirmation and your e-ticket soon."])},
    "status_title_cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的預訂已取消"])},
    "status_title_issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的預訂已處理完成"])},
    "status_title_paymenting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的預訂等待支付"])},
    "status_title_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的預訂正在處理中"])},
    "ticket_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票中…"])},
    "status_title_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的預訂已完成支付"])},
    "orderDetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單詳情"])},
    "order_cancel_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預訂取消失敗"])},
    "order_new_not_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若長時間無法加載訂單詳情，請稍後至訂單頁中查看。"])},
    "toOrderDetail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["前往 ", _interpolate(_list(0))])},
    "sendItinerary_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發送失敗"])},
    "sendItinerary_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發送成功"])},
    "receivingEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件郵箱"])},
    "receivingEmailRequred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請填寫收件郵箱"])},
    "orderPriceSummaryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳單細則"])},
    "orderPriceTotalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總計"])},
    "orderListActionChangeFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改行程"])},
    "orderListActionChangeSeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選座"])},
    "orderListActionSendItinerary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發送行程"])},
    "orderListActionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇您的操作"])},
    "orderListActionViewTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看/管理行程"])},
    "orderPaySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付成功"])},
    "status_title_issued_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出票失敗"])}
  },
  "responseMsg": {
    "1050": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您所選價格已無比特，請重新搜索"])},
    "1055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程資訊錯誤，請重新搜索"])},
    "1056": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["乘客資訊錯誤"])},
    "1058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["連絡人資訊錯誤"])},
    "1059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所選行程價格有變化，請重新搜索"])},
    "1060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下單失敗，請稍後重試"])},
    "1061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下單失敗，請稍後重試"])},
    "1062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["證件有效期超出限制範圍，請重新填寫"])},
    "1201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數據過期，請重新搜索"])},
    "1202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程資訊錯誤"])},
    "1206": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["艙位或價格變化，請重新搜索"])},
    "1207": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["艙位變化，請重新搜索"])},
    "1208": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["艙位變化，請重新搜索"])},
    "1209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系統錯誤，請稍後重試"])},
    "1401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參數校驗失敗，請重新填寫"])},
    "1402": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參數校驗失敗，請稍後重試"])},
    "1403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參數校驗失敗，請稍後重試"])},
    "1404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單不存在，請重新搜索"])},
    "1405": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單狀態异常，請重新搜索"])},
    "1406": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超出支付時限，請重新搜索"])},
    "1407": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付校驗失敗，請重新搜索"])},
    "1501": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数校验失败，请稍后重试"])},
    "1502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單不存在，請重新搜索"])},
    "1503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無權操作該訂單"])},
    "1504": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單不為等待支持狀態，請重新搜索"])},
    "1505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不支持的支付方式"])},
    "1506": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超出支付時限，請重新搜索"])},
    "1507": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付驗證失敗，請重新搜索"])},
    "1510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶餘額不足"])},
    "1511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失敗，請重新搜索"])},
    "1512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付處理中"])},
    "1513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單已支付"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失敗，請稍後重試"])},
    "priceChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所選行程價格有變化，請重新搜索"])},
    "ticketPriceChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所选行程价格有变化，请重新搜索"])},
    "invalidCraditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡未經授權"])},
    "invalidCraditCardExpiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡到期日期無效"])},
    "invalidCraditCardNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡號無效"])},
    "invalidFlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行程無效，請重新搜索"])},
    "noTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暫無機票"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
    "logoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["註銷"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳號"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
    "loginInfoError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶名或密碼錯誤"])},
    "loginFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作失敗，請稍後重試"])},
    "loginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
    "accountRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請填寫帳戶名"])},
    "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請填寫密碼"])},
    "b2bUnRegisterNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵箱未注册"])},
    "emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無效的郵箱"])},
    "sendCodeFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發送驗證碼失敗"])},
    "sendCodeSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發送驗證碼成功"])},
    "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證碼"])},
    "verificationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驗證碼錯誤"])},
    "verificationCodeRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請填寫驗證碼"])},
    "verificationCodeSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發送驗證碼"])}
  },
  "validate": {
    "emptyTip": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["請填寫", _interpolate(_named("key"))])},
    "passenger": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請確認乘客資訊填寫完整"])}
    },
    "contact": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請確認連絡人資訊填寫完整"])}
    },
    "payment": {
      "lack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认支付信息填写完整"])}
    }
  },
  "seatmap": {
    "passengerText": {
      "ADT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成人"])},
      "CHD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兒童"])}
    },
    "seatmapSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存座位資訊失敗"])},
    "seatmapSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存座位資訊成功"])},
    "seatmapTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["座位"])}
  },
  "service": {
    "serviceSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存服務資訊失敗"])},
    "serviceSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存服務資訊成功"])},
    "serviceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加服務"])}
  },
  "bookPolicy": {
    "privacyPolicyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私政策"])},
    "restrictionRulesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票價限制和規則"])},
    "restrictionRulesBtnText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["規則"])}
  },
  "exchange": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
    "changingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改中..."])},
    "exchangeFeeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["航司費用"])},
    "exchangeFormInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請確認資訊填寫完整"])},
    "exchangeRequestFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交失敗"])},
    "tripInTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返程"])},
    "tripIndexTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["第", _interpolate(_named("index")), "程"])},
    "tripOutTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["去程"])},
    "exchangeDataInvalidTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["換票數據無效！"])},
    "exchangeFareTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票價"])},
    "exchangeSearchFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蒐索失敗"])},
    "exchangeSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的換票已處理完成"])},
    "exchangeSuccessTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your booking is confirmed and reissued. We wish you a wonderful flight."])}
  },
  "pricing": {
    "analyzingFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請求失敗"])},
    "getUserFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入失敗"])},
    "priceFormInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請填寫文字"])}
  }
}