export const orderRouters = [
  {
    path: 'orders',
    name: 'orders',
    component: () => import('../views/orders/myOrders/MyOdersView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'order/:id',
    name: 'orderDetail',
    component: () => import('../views/orders/OrderDetail/OrderDetailView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'order/pay/:id',
    name: 'orderPay',
    component: () => import('../views/orders/orderPay/OrderPayView.vue'),
    meta: {
      requiresAuth: true
    }
  }
];
