import { searchAirports } from '@/api/flight';
export const useAirportsStore = defineStore('airports', () => {
  const airportsMap = reactive({});
  async function getAirports(key, lan) {
    const localeKey = key + '-' + lan;
    if (airportsMap[localeKey]) return airportsMap[localeKey];
    try {
      const res = await searchAirports({
        string: key,
        lan
      });
      const data = res.airportListResponse.airports.map(a => {
        const temp_arr = a.split(' ');
        const code = temp_arr.shift().slice(1, -1);
        return {
          label: temp_arr.join(' '),
          value: code
        };
      });
      airportsMap[localeKey] = data;
      return data;
    } catch (e) {
      return [];
    }
  }
  return {
    airportsMap,
    getAirports
  };
});