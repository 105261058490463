import dataEn from '@/assets/data/airData-en.json';
import dataZhCN from '@/assets/data/airData-zh-CN.json';
import dataZhTW from '@/assets/data/airData-zh-TW.json';
export function useAirDataTranslate() {
  function getLocaleData(locale, cat) {
    if (locale === 'zh-CN') {
      return dataZhCN[cat];
    } else if (locale === 'zh-TW') {
      return dataZhTW[cat];
    } else {
      return dataEn[cat];
    }
  }
  function translateAirport(locale, key) {
    const text = ref();
    watchEffect(() => {
      const data = getLocaleData(locale.value, 'AIRPORT');
      text.value = data[key];
    });
    return text;
  }
  function translateAirportName(locale, key) {
    const text = ref();
    watchEffect(() => {
      const data = getLocaleData(locale.value, 'AIRPORT');
      text.value = data[key] ? data[key]['AIRPORTNAME'] : '';
    });
    return text;
  }
  function translateAirportCity(locale, key) {
    const text = ref();
    watchEffect(() => {
      const data = getLocaleData(locale.value, 'AIRPORT');
      text.value = data[key] ? data[key]['CITYNAME'] : '';
    });
    return text;
  }
  function translateAirline(locale, key) {
    const text = ref();
    watchEffect(() => {
      const data = getLocaleData(locale.value, 'AIRLINE');
      text.value = data[key];
    });
    return text;
  }
  return {
    translateAirport,
    translateAirportName,
    translateAirportCity,
    translateAirline
  };
}