import { Collapse } from 'bootstrap';

/**
 *
 * vue custom directive for bootstrap collapse
 * usage: v-g-toggle:[collapseId].animate.show
 */

// Determine if an element is an HTML element
const isElement = el => !!(el && el.nodeType === Node.ELEMENT_NODE);
// Set an attribute on an element
const setAttr = (el, attr, value) => {
  if (attr && isElement(el)) {
    el.setAttribute(attr, value);
  }
};

// Remove an attribute from an element
// const removeAttr = (el, attr) => {
//   if (attr && isElement(el)) {
//     el.removeAttribute(attr);
//   }
// };

const VGToggle = {
  async mounted(el, binding) {
    await nextTick();
    const collapseEl = document.querySelector(`#${binding.arg}`);
    if (!collapseEl) {
      console.error(el, 'there is no collapse element provide');
      return;
    }
    let toggle = false;
    if (binding.modifiers && binding.modifiers.show) {
      toggle = true;
    }
    let toggler = null;
    // get toggler element
    if (binding.value) {
      toggler = el.querySelector(binding.value);
    } else {
      toggler = el;
    }
    toggler.classList.add('collapse-toggler');

    // add collapse toggle animation class
    if (binding.modifiers && binding.modifiers.animate) {
      toggler.classList.add('collapse-animation');
    }
    if (!toggle) {
      toggler.classList.add('collapsed');
    }
    let parent = null;
    if (el.dataset['bsParent']) parent = el.dataset['bsParent'];
    setAttr(toggler, 'aria-expanded', toggle);
    setAttr(toggler, 'aria-controls', binding.arg);
    const bsCollapse = new Collapse(collapseEl, {
      toggle,
      parent
    });
    el.instance = bsCollapse;
    el.toggleState = toggle;
    const handler = () => {
      el.instance.toggle();
      el.toggleState = !el.toggleState;
      toggler.classList.toggle('collapsed');
      setAttr(toggler, 'aria-expanded', el.toggleState);
    };
    el.addEventListener('click', handler);
    el['collapse_click_handler'] = handler;
  },
  // updated(el, binding) {
  //   if (binding.value) {
  //     el.instance.show()
  //   } else {
  //     el.instance.hide()
  //   }
  // },
  beforeUnmount(el) {
    if (el['collapse_click_handler']) {
      el.removeEventListener('click', el['collapse_click_handler']);
      el['collapse_click_handler'] = null;
      el['instance'] = null;
    }
  }
};
export default VGToggle;