import { API_URL, customersessionid } from './config';
import { gtGet, gtPost } from './lib/gtRequest';
// import * as gtBookRequest from './lib/gtBookRequest';
import { get } from './lib/request';
import { addDevPrefix } from './lib/util';

import { getApiKey } from '@/composables/app/useApiKey';

// airports list request, use gtt api
export const searchAirports = (params) => {
  params['cid'] = 'dggtpe';
  params['customersessionid'] = customersessionid;

  return get(addDevPrefix('airport') + API_URL.flight.searchAirports, {
    params,
    baseURL:
      process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_API_AIRPORT_URL
        : ''
  });
};

/**
 * query tickets of oneway or roundtrip
 * @param {*} params
 * @returns
 */
export const searcAirTicketsOneRound = (params) => {
  // params['cid'] = cid;
  params['customersessionid'] = customersessionid;

  return gtGet(API_URL.flight.searcAirTicketsOneRound, {
    params,
    isMock: false
  });
};

/**
 * query tickets of multicities
 * @param {*} params
 * @returns
 */
export const searcAirTicketsMulty = (params) => {
  // params['cid'] = cid;
  params['customersessionid'] = customersessionid;

  return gtGet(API_URL.flight.searcAirTicketsMulty, {
    params
  });
};

// Prebooking 验证价格、退改签和行李信息联合接口
export const preBooking = (params) => {
  // params['cid'] = cid;
  params['customersessionid'] = customersessionid;

  // return gtBookRequest.post(API_URL.flight.preBook, params, {
  //   isMock: false
  // });
  return gtPost(API_URL.flight.preBook, params, {
    isMock: false
  });
};

export const submitBook = (params) => {
  // params['cid'] = cid;
  params['customersessionid'] = customersessionid;

  // return gtBookRequest.get(API_URL.flight.createOrder, {
  //   params,
  //   isMock: false
  // });
  return gtPost(API_URL.flight.createOrder, params, {
    isMock: false,
    headers: {
      ...getApiKey()
    }
  });
};

// 价格校验接口
// export const checkVerify = (params) => {
//   params['cid'] = cid;
//   params['customersessionid'] = customersessionid;

//   const baseURL =
//     process.env.NODE_ENV === 'production'
//       ? process.env.VUE_APP_API_GTT_BOOK_HOST
//       : '';

//   return gtGet(API_URL.flight.checkVerify, {
//     baseURL,
//     params
//   });
// };

// 生单接口
export const createOrder = (params) => {
  params['cid'] = 'dggtpe';
  params['customersessionid'] = customersessionid;

  const baseURL =
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_API_GTT_BOOK_HOST
      : '';

  return params(API_URL.flight.createOrder, params, {
    baseURL
  });
};
