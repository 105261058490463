import dayjs from 'dayjs';

import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/fr';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

/**
 * 按指定格式格式化日期时间, [https://dayjs.gitee.io/docs/zh-CN/display/format]
 * @param {*} timestamp Unix 时间戳 (毫秒)
 * @param {*} format
 * @returns
 */
export const formatDateTime = (timestamp, format) => {
  const dayjsObj = dayjs(timestamp);

  return dayjsObj.isValid() ? dayjsObj.format(format) : '';
};

/**
 * 根据不同语言配置格式化日期时间
 * @param {*} timestamp
 * @param {*} format
 * @param {*} locale 语言
 * @returns
 */
export const formatDateTimeLocale = (timestamp, format, locale = 'en') => {
  const dayjsObj = dayjs(timestamp);

  return dayjsObj.isValid() ? dayjsObj.locale(locale).format(format) : '';
};

/**
 *
 * @param {*} start
 * @param {*} end
 * @returns
 */
export const formatDuration = (start, end, format) => {
  const date_start = dayjs(start, format);
  const date_end = dayjs(end, format);

  return dayjs
    .duration(date_end.diff(date_start))
    .format('YY/MM/DD/H/m/s')
    .split('/');
};

/**
 * 判断2个日期是否相同（按天）
 * @param {*} date1
 * @param {*} date2
 * @returns
 */
export const isEqual = (date1, date2, unit = 'day') => {
  return dayjs(date1).isSame(dayjs(date2), unit);
};

/**
 * 判断时间date1是否在date2之前
 * @param {*} date1
 * @param {*} date2
 * @param {*} unit
 * @returns
 */
export const isBefore = (date1, date2, unit = 'millisecond') => {
  return dayjs(date1).isBefore(dayjs(date2), unit);
};

/**
 * 判断时间date1是否不超过date2
 * @param {*} date1
 * @param {*} date2
 * @param {*} unit
 * @returns
 */
export const isSameOrBeforeDay = (date1, date2, unit = 'millisecond') => {
  return dayjs(date1).isSameOrBefore(dayjs(date2), unit);
};

const FORMAT_MDY = 'MMDDYYYY';
/**
 * 编码日期为 MMDDYYYY格式的字符串
 * @param {*} date
 * @returns
 */
export const encodeMDY = (date) => {
  return dayjs(date).format(FORMAT_MDY);
};

/**
 * 解码日期格式为 MMDDYYYY 的字符串, 返回日期格式数据
 * @param {*} date
 * @returns
 */
export const decodeMDY = (date) => {
  return dayjs(date, FORMAT_MDY).toDate();
};

/**
 * 将时间格式化为 1d 2h 25m 字符串
 * @param {*} time
 * @param {*} u 格式化最小单位
 * @returns
 */
export const formatFromDuration = (time, u = 'minutes') => {
  let td = 0;
  try {
    td = parseInt(time);
  } catch (e) {
    return 'error time type';
  }

  const duration = dayjs.duration(td, u);

  const text = [];
  if (duration.days() > 0) {
    text.push(duration.days() + 'd');
  }
  if (duration.hours() > 0) {
    text.push(duration.hours() + 'h');
  }
  if (duration.minutes() > 0) {
    text.push(duration.minutes() + 'm');
  }

  return text.join(' ');
};

/**
 * 根据时长返回对象
 * @param {*} time
 * @param {*} u
 * @returns object{days: number, hours: number, minutes: number}
 */
export const formatDurationToObject = (time, u = 'minutes') => {
  let td = 0;
  try {
    td = parseInt(time);
  } catch (e) {
    return 'error time type';
  }

  const duration = dayjs.duration(td, u);

  const text = {};
  if (duration.days() > 0) {
    text['days'] = duration.days();
  }
  if (duration.hours() > 0) {
    text['hours'] = duration.hours();
  }
  if (duration.minutes() > 0) {
    text['minutes'] = duration.minutes();
  }

  return text;
};

/**
 * 将 MM/DD/YYYY 格式日期转为特定格式日期
 * @param {*} mdy MM/DD/YYYY
 * @param {*} format
 * @returns YYYYMMDD
 */
export const transformMDY = (mdy, format = 'MMDDYYYY') => {
  return formatDateTime(dayjs(mdy, 'MM/DD/YYYY'), format);
};

/**
 * 将 MMDDYYYY 格式日期转为特定格式日期
 * @param {*} mdy
 * @param {*} format
 * @returns
 */
export const transformMDYNum = (mdy, format = 'MMDDYYYY') => {
  return formatDateTime(dayjs(mdy, 'MMDDYYYY'), format);
};

/**
 * MMDDYYYY,MM/DD/YYYY 格式日期相互转换
 * @param {*} str
 * @param {*} toSlash 是否转为带斜线的格式
 * @returns
 */
export const transformMDYMutual = (str, toSlash = false) => {
  return toSlash ? transformMDYNum(str, 'MM/DD/YYYY') : transformMDY(str);
};

/**
 * 用于数组排序中时间比较,按日期时间戳大小
 * @param {*} date1
 * @param {*} date2
 * @returns 负数、0、正数
 * see https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#comparefn
 */
export const dateSort = (date1, date2) => {
  return dayjs(date1).unix() - dayjs(date2).unix();
};

/**
 * 获取日期时间中某一信息，例如：小时数字，分钟数字，年数字
 * see https://dayjs.fenxianglu.cn/category/getset.html#get
 * @param {*} date
 */
export const pickDateItem = (date, unit) => {
  const d = dayjs(date);
  if (!d.isValid() || !unit) return NaN;
  return d.get(unit);
};

/**
 * 将日期时间转为时间戳
 * @param {String} date 要转换的日期时间字符串
 * @returns Number
 */
export const toUnix = (date) => {
  return dayjs(date).unix();
};

/**
 * 获取起始日期前len 单位的日期
 * @param {Date} currentDate 起始日期
 * @param {Number} len 计算长度
 * @param {'day'|'month'|'year'|'hour'|'minutes'} unit 计算单位
 * @returns
 */
export const getDateFromAgo = (currentDate, len, unit = 'day') => {
  return dayjs(currentDate).subtract(len, unit);
};

/**
 *
 * @param {string|dayjs} date 日期格式字符串或dayjs日期对象
 * @returns number
 */
export const getUTCtimestamp = (date) => {
  return dayjs(date).valueOf();
};

// function getUTCtimestamp() {
//   var localOffset = new Date().getTimezoneOffset() * 60000; // 本地时区的偏移量，单位是毫秒
//   var utc = Date.now(); // 获取UTC时间
//   return utc + localOffset; // 返回本地时间转换为UTC时间后的时间戳
// }

/**
 * 判断字符串是否为 ISO86 格式日期
 * @param {*} str
 * @returns Boolean
 */
export const isIsoDate = (str) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d) && d.toISOString() === str; // valid date
};

export const toISOString = (date) => {
  const dayjsObj = dayjs(date);

  return dayjsObj.isValid() ? dayjsObj.toISOString() : 'invalid date';
};

export function getUTCtimestamp2() {
  var localOffset = new Date().getTimezoneOffset() * 60000; // 本地时区的偏移量，单位是毫秒
  var utc = Date.now(); // 获取UTC时间
  return utc + localOffset; // 返回本地时间转换为UTC时间后的时间戳
}
