import { login, register } from '@/api/auth';
import { useAppStore } from './app';
import { useBookStore } from './book';
export const useUserStore = defineStore('user', () => {
  const appStore = useAppStore();
  const bookStore = useBookStore();
  const userId = ref('');
  const userName = ref('');
  const LimitAmount = ref(0);
  const LimitCabin = ref(0);
  const isLoggedIn = computed(() => {
    return !!userName.value;
  });
  function $reset() {
    userId.value = '';
    userName.value = '';
    LimitAmount.value = 0;
    LimitCabin.value = 0;
  }
  async function toLogin({
    account,
    password
  }) {
    try {
      const data = {
        Username: account,
        Password: password
      };
      const resData = await login(data);
      const res = resData.Data;
      userId.value = res.id;
      userName.value = res.name;
      LimitAmount.value = res.LimitAmount;
      LimitCabin.value = res.LimitCabin;
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async function toLogOff() {
    $reset();
    appStore.closeInterCom();
    bookStore.resetBook();
    return Promise.resolve(true);
  }
  async function toReg({
    account,
    password
  }) {
    try {
      const data = {
        Username: account,
        Password: password
      };
      const resData = await register(data);
      const res = resData.Data;
      return res;
    } catch (err) {
      return Promise.reject(err);
    }
  }
  return {
    $reset,
    userId,
    userName,
    isLoggedIn,
    LimitCabin,
    LimitAmount,
    toLogin,
    toLogOff,
    toReg
  };
});