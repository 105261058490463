const localKey = 'xapikey';

export function getApiKey() {
  // const key = localStorage.getItem(localKey);
  const key = 'FgDfRwx5aJuvJEVO1LUiEloP4iPXMp1D';

  return key ? { 'x-api-key': key } : null;
}

export function setApiKey(key) {
  if (key) {
    localStorage.setItem(localKey, key);
  }
}

export function useApiKey() {
  function getApiKey() {
    const key = localStorage.getItem(localKey);

    return key ? { 'x-api-key': key } : null;
  }

  function setApiKey(key) {
    if (key) {
      localStorage.setItem(localKey, key);
    }
  }

  return {
    getApiKey,
    setApiKey
  };
}
