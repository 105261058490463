import { isMobile } from '@/utils/browser';

import fontawesome from './fontawesome';

import i18n from './i18n';

function setGlobalProperties(app) {
  app.config.globalProperties.$_isMobile = isMobile;
}

export default {
  install(app) {
    setGlobalProperties(app);

    app.use(i18n);
    app.use(fontawesome);
  }
};
