import { Offcanvas } from 'bootstrap';

/**
 *
 * vue custom directive for bootstrap Offcanvas
 * usage: v-g-offcanvas:[collapseId].show
 */

// Determine if an element is an HTML element
const isElement = el => !!(el && el.nodeType === Node.ELEMENT_NODE);
// Set an attribute on an element
const setAttr = (el, attr, value) => {
  if (attr && isElement(el)) {
    el.setAttribute(attr, value);
  }
};

// Remove an attribute from an element
// const removeAttr = (el, attr) => {
//   if (attr && isElement(el)) {
//     el.removeAttribute(attr);
//   }
// };

const VGOffcanvas = {
  async mounted(el, binding) {
    await nextTick();
    const targetEl = document.querySelector(`#${binding.arg}`);
    if (!targetEl) {
      console.error(el, 'there is no Offcanvas element provide');
      return;
    }
    let toggle = false;
    if (binding.modifiers && binding.modifiers.show) {
      toggle = true;
    }
    let toggler = null;
    // get toggler element
    if (binding.value) {
      toggler = el.querySelector(binding.value);
    } else {
      toggler = el;
    }
    setAttr(toggler, 'aria-controls', binding.arg);
    const scroll = targetEl.dataset['scroll'];
    const backdrop = targetEl.dataset['backdrop'];
    const bsOffcanvas = new Offcanvas(targetEl, {
      backdrop: backdrop === 'static' ? backdrop : new Boolean(backdrop),
      scroll: new Boolean(scroll)
    });
    el._offcanvasInstance = bsOffcanvas;
    targetEl._offcanvasInstance = bsOffcanvas;
    const handler = () => {
      el._offcanvasInstance.toggle();
    };
    el.addEventListener('click', handler);
    el['offcanvas_click_handler'] = handler;
    if (toggle) bsOffcanvas.show();
  },
  // updated(el, binding) {
  //   if (binding.value) {
  //     el.instance.show()
  //   } else {
  //     el.instance.hide()
  //   }
  // },
  beforeUnmount(el, binding) {
    if (el['offcanvas_click_handler']) {
      el.removeEventListener('click', el['offcanvas_click_handler']);
      el['offcanvas_click_handler'] = null;
      el['_offcanvasInstance'] = null;
    }
    const targetEl = document.querySelector(`#${binding.arg}`);
    targetEl._offcanvasInstance = null;
  }
};
export default VGOffcanvas;