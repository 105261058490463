import mockApi from './mock';

export function addDevPrefix(prefix) {
  return process.env.NODE_ENV === 'development' ? `/${prefix}` : '';
}

export function urlAddPrefix(config, prefix) {
  const { url } = config;

  if (config.isMock) {
    config = mockApi(config);
  } else {
    config.url = prefix + url;
  }

  return config;
}
