// import { useAirDataTranslate } from './useAirDataTranslate';
import { useAirportsStore } from '@/store/airports';
export function useQueryAirport() {
  const airportsStore = useAirportsStore();
  // const airDataTranslate = useAirDataTranslate();
  const {
    locale
  } = useI18n();
  return async (queryString, cb) => {
    const data = await airportsStore.getAirports(queryString, locale.value);

    // const resData = data.filter((ad) => {
    //   return ad.label.toLowerCase().indexOf('all airports') === -1;
    // });
    // .map((rd) => {
    //   return {
    //     ...rd,
    //     label: `
    //     ${airDataTranslate.translateAirportName(locale, rd.value).value}
    //     -
    //     ${airDataTranslate.translateAirportCity(locale, rd.value).value}`
    //   };
    // });

    cb(data);
  };
}