import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { isMiniProgram, isWxWeb } from '@/utils/browser';
import { useWxUserStore, PLATFORM_TYPE } from '@/store/wxUser';
export function useWxUser() {
  const route = useRoute();
  const wxUserStore = useWxUserStore();
  async function initUser() {
    if (!isMiniProgram() && !isWxWeb()) return false;
    let p = '';
    let u = '';
    if (route?.query) {
      p = route.query.p;
      u = route.query.u;
    } else {
      const hash = window.location.hash;
      const query = hash.split('?');
      if (query[1]) {
        const searchParams = new URLSearchParams(query[1]);
        p = searchParams.get('p');
        u = searchParams.get('u');
      }
    }

    // for weixin webpage
    if (p) {
      wxUserStore.setPlatform(PLATFORM_TYPE.WXWEB);
      wxUserStore.setOpenId(p);
      return wxUserStore.getWxUserInfo();
    } else if (u) {
      // for miniprogram
      wxUserStore.setPlatform(PLATFORM_TYPE.MINIPROGRAM);
      wxUserStore.setOpenId(u);
      return wxUserStore.getWxUserInfo();
    }
  }
  function initWxUser(toRouter) {
    if (!isMiniProgram() && !isWxWeb()) return false;
    const p = toRouter.query.p;
    const u = toRouter.query.u;
    if (p) {
      wxUserStore.setPlatform(PLATFORM_TYPE.WXWEB);
      wxUserStore.setOpenId(p);
      return wxUserStore.getWxUserInfo();
    } else if (u) {
      // for miniprogram
      wxUserStore.setPlatform(PLATFORM_TYPE.MINIPROGRAM);
      wxUserStore.setOpenId(u);
      return wxUserStore.getWxUserInfo();
    }
  }
  return {
    initUser,
    initWxUser
  };
}