import { generateRandomString } from '@/utils/util';

export const API_URL = {
  app: {
    getCurrencyconverter: '/api/currencyconverter'
  },
  auth: {
    login: '/api/users',
    register: '/api/'
  },
  wxUser: {
    getUserInfo: '/api/Users'
  },
  flight: {
    searchAirports: '/airticket/v1/airport.aspx',
    // searcAirTicketsOneRound: '/airticket/v1/list.aspx',
    // searcAirTicketsMulty: '/airticket/v1/multicity.aspx',
    // createOrder: '/airticket/v1/res.aspx',
    // seatMap: '/airticket/v1/seatsmap.aspx',
    // preBook: '/airticket/v1/prebooking.aspx',
    searcAirTicketsOneRound: '/api/AirTickets',
    searcAirTicketsMulty: '/api/AirTickets/multicity',
    createOrder: '/api/Book',
    seatMap: '/api/Seatmap',
    preBook: '/api/Prebooking'
  },
  exchange: {
    // search: '/AirTicket/v1/ExchangeSearch.aspx',
    // order: '/AirTicket/v1/ExchangeOrder.aspx'
    search: '/api/Exchange/Search',
    order: '/api/Exchange/Order'
  },
  b2b: {
    loginWithVerifyCode: '/api/Account/VerifyMailVerificationCode',
    sendVerifyCode: '/api/Account/SendMailVerificationCode'
  },
  order: {
    getMyOrders: '/api/Orders',
    getOrderDetail: '/api/OrderDetail',
    pay: '/api/Payment',
    cancel: '/api/Cancel',
    getSeats: '/api/Seatmap/order',
    getServices: '/api/Services',
    reserveSeats: '/api/ReserveSeats',
    reserveServices: '/api/ReserveServices',
    checkTicketStatus: '/api/TicketStatus',
    sendItinerary: '/api/Mails/SendItinerary'
  },
  accurate: {
    routesearch: '/api/AirTickets/SearchByRouting'
  },
  gttb2b: {
    getSign: '/UserInfoAuth.ashx',
    getUserData: '/UserInfoAuth.ashx',
    getAnalyzingData: '/UserAuth.ashx'
  }
};

export const customersessionid = generateRandomString(15);

export const cid = 'flxndc999';
