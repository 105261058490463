export const tourRouters = [
  {
    path: 'tours',
    name: 'TourList',
    component: () => import('../views/tour/tourList/TourListView.vue')
  },
  {
    path: 'tour/:id',
    name: 'TourDetail',
    component: () => import('../views/tour/tourDetail/TourDetailView.vue')
  }
];
