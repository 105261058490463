export const getNavigatorLanguage = () => {
  // 获取navigator
  const navigator = window?.navigator;

  // 查看当前浏览器是否支持language
  // const isSupported = Boolean(navigator && 'language' in navigator);

  // 存储当前language
  const language = navigator?.language || 'en';

  // 监听languagechange事件，如果有变化，就更新存储的language值
  // useEventListener(window, 'languagechange', () => {
  //   if (navigator) {
  //     setLanguage(navigator.language);
  //   }
  // });

  return language;
};
