import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

import BaseCollapse from './BaseCollapse';
import TheDropPop from './TheDropPop/TheDropPop.vue';
import VGOffcanvas from './BaseOffcanvas/index.js';
import DurationText from './TheDateText/DurationText.vue';
import ShortDate from './TheDateText/ShortDate.vue';
import ValidatorContainer from './ValidatorForm/ValidatorContainer.vue';
import ValidatorItem from './ValidatorForm/ValidatorItem.vue';

import AirlineText from './AirText/AirlineText.vue';
import AircityText from './AirText/AircityText.vue';
import AirportText from './AirText/AirportText.vue';

export default {
  install: (app) => {
    const requireComponent = require.context(
      './',
      true,
      /^\.\/Base\w+\/\w+\.vue$/,
      'eager'
    );
    requireComponent.keys().forEach((fileName) => {
      const componentConfig = requireComponent(fileName);
      const componentName = upperFirst(
        camelCase(
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '')
        )
      );

      app.component(componentName, componentConfig.default || componentConfig);
    });

    app.component(ValidatorContainer.name, ValidatorContainer);
    app.component(ValidatorItem.name, ValidatorItem);

    app.component(TheDropPop.name, TheDropPop);
    app.component(DurationText.name, DurationText);
    app.component(ShortDate.name, ShortDate);

    app.component(AirlineText.name, AirlineText);
    app.component(AircityText.name, AircityText);
    app.component(AirportText.name, AirportText);

    app.directive('GToggle', BaseCollapse);
    app.directive('GOffcanvas', VGOffcanvas);
  }
};
