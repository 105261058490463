import SearchFlights from './components/searchFlight/SearchFlights.vue';
import SearchTour from './components/searchTour/SearchTour.vue';
import { useWxUser } from '@/composables/app/useWxUser';
const formTypes = {
  FLIGHT: 'flight',
  TOUR: 'tour'
};
export default {
  components: {
    SearchFlights,
    SearchTour
  },
  setup() {
    const {
      initUser
    } = useWxUser();
    initUser();
  },
  data() {
    return {
      formTypes,
      selectFormType: formTypes.FLIGHT
    };
  },
  methods: {
    handleChangeForm(type) {
      this.selectFormType = type;
    }
  }
};