import { createRouter, createWebHashHistory } from 'vue-router';
import LayoutView from '@/layout/LayoutView.vue';
import HomeView from '../views/home/HomeView.vue';

import { accurateRouters } from './accurate';
import { exchangeRouters } from './exchange';
import { tourRouters } from './tour';
import { flightRouters } from './flight';
import { orderRouters } from './order';

const routes = [
  {
    path: '/',
    component: LayoutView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/auth/LoginB2bView.vue')
      },
      {
        path: 'register',
        component: () => import('../views/auth/RegisterView.vue')
      },
      {
        path: 'test',
        component: () => import('../views/test/TestView.vue')
      },
      ...flightRouters,
      ...orderRouters,
      ...tourRouters
    ]
  },
  accurateRouters,
  exchangeRouters,
  {
    path: '/400',
    name: '400',
    component: () => import('@/views/error/PageError400.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/error/PageError404.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 };
  }
});

export default router;
