import axios from 'axios';
import interceptors from './interceptors/accurate';
import _ from 'lodash';

const axInstance = axios.create({
  timeout: 50000
});

interceptors.request.forEach((interceptor) => {
  const toUseInterceptor = interceptor();
  if (_.isArray(toUseInterceptor)) {
    axInstance.interceptors.request.use(...toUseInterceptor);
  } else {
    axInstance.interceptors.request.use(toUseInterceptor);
  }
});

interceptors.response.forEach((interceptor) => {
  const toUseInterceptor = interceptor();
  if (_.isArray(toUseInterceptor)) {
    axInstance.interceptors.response.use(...toUseInterceptor);
  } else {
    axInstance.interceptors.response.use(toUseInterceptor);
  }
});

export const get = (url, config) => {
  return axInstance.get(url, config);
};

export const post = (url, data, config) => {
  return axInstance.post(url, data, config);
};
